export default function ({ store, $axios, redirect, $sentry, $config }, inject) {
	$axios.onRequest((config) => {
		if (process.server) {
			config.headers["static-generate-key"] = process.env.apiKeyForGenerate;
		} else {
			config.headers["api-key"] = process.env.apiKey;

			if (config.url.startsWith("/Checkin/") && store.state.authentication.tokenInfo.token) {
				config.headers.Authorization = "Bearer " + store.state.authentication.tokenInfo.token
			}
		}

		if (!config.params || (!!config.params && !config.params.async)) {
			store.dispatch("eventBus/loading", { isLoading: true })
		}
	})

	$axios.onRequestError((config) => {
		store.dispatch("eventBus/loading", { isLoading: false })
	})

	$axios.onResponse((config) => {
		store.dispatch("eventBus/loading", { isLoading: false })
	})

	$axios.onResponseError((config) => {
		store.dispatch("eventBus/loading", { isLoading: false })
	})

	$axios.onError((error) => {
		store.dispatch("eventBus/loading", { isLoading: false })

		if (process.server) {
			const shouldAvoidSentry = false
				|| (error.response?.config.url === '/booking/confirmation' && error?.response?.data?.errors?.[0] === 'Token expired or empty')

			$sentry.captureException(shouldAvoidSentry ? new Error('AVOID SENTRY') : new Error(error))
		}

		if (error.response?.config) {
			const url = error.response.config.url;
			if (error.response?.status === 404 && !url.includes(".json")) {
				redirect("/404")
				return;
			}

			if (error.response?.status === 401) {
				if (!process.server && window.location.href.includes('/business')) {
					redirect("/business/sign-in")
					return;
				}
				redirect("/sign-in/")
			}
		}

		return Promise.resolve(error.response)
	})

	// Set up Strapi instance
	const strapi = $axios.create({
		baseURL: $config.strapiUrl,
		timeout: 3000,
	})

	strapi.onRequest((config) => {
		if (config.headers?.common?.Authorization) {
			delete config.headers.common.Authorization
		}
		if (config.headers?.Authorization) {
			delete config.headers.Authorization
		}
	})

	inject('strapi', strapi)
}
