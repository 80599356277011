import { breakpoints } from "~/assets/scripts/valueObjects/breakpoints.js"

export const state = () => ({
	isVisible: true,
	isIdle: true,
	breakpoint: {
		isMobileOnly: true,
		isMobile: true,
		isTablet: false,
		isTabletWide: false,
		isDesktop: false,
		isDesktopWide: false,
	}
})

export const getters = {
	current: state => {
		const { isDesktopWide, isDesktop, isTabletWide, isTablet } = state.breakpoint
		return	  isDesktopWide	? "desktopWide"
				: isDesktop		? "desktop"
				: isTabletWide	? "tabletWide"
				: isTablet		? "tablet"
				: "mobile"
	},
}

export const mutations = {
	updateBreakpoint(state, { breakpoint, value }) {
		state.breakpoint[breakpoint] = value
	},
	toggleIsIdle(state, bool) {
		state.isIdle = bool
	},
	toggleIsVisible(state, bool) {
		state.isVisible = bool
	},
}

export const actions = {
	initIsVisible({ state, commit }) {
		const toggleIsVisible = () => {
			commit('toggleIsVisible', !document.hidden)
		}

		document.addEventListener("visibilitychange", toggleIsVisible);
		toggleIsVisible()
	},
	/**
	 * Based on plugin:, Defaults taken from plugin:
	 * (https://github.com/soixantecircuits/idle-js)
	 */
	initIdle({ state, commit }) {
		const idle = 10000 // idle time in ms
		const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'] // events that will trigger the idle resetter

		const commitIsIddle = () => {
			if (!state.isIdle) return

			commit('toggleIsIdle', false)
			setTimeout(() => {
				commit('toggleIsIdle', true)
			},  idle)
		}

		events.forEach(e => { document.addEventListener(e, commitIsIddle) })
	},
	/**
	 * Breakpoints behave as in Tailwind mobile first.
	 * (https://tailwindcss.com/docs/responsive-design#mobile-first)
	 * e.g. isMobile is true even when isDesktop is true.
	 */
	initBreakpoints({ commit }) {
		if (window !== 'undefined') {
			/**
			 * Because isMobile in a 'mobile first' is always true, we use isMobileOnly that is the same as !isTablet
			 */
			const isMobileOnly = window.matchMedia(`(min-width: ${breakpoints.tablet}px)`)
			const isTablet = window.matchMedia(`(min-width: ${breakpoints.tablet}px)`)
			const isTabletWide = window.matchMedia(`(min-width: ${breakpoints.tabletWide}px)`)
			const isDesktop = window.matchMedia(`(min-width: ${breakpoints.desktop}px)`)
			const isDesktopWide = window.matchMedia(`(min-width: ${breakpoints.desktopWide}px)`)

			/** https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/onchange */
			const isOnChangeSupported = 'onchange' in isMobileOnly

			isMobileOnly.onchange = (event) => { commit('updateBreakpoint', { breakpoint: 'isMobileOnly', value: !event.matches }) }
			isTablet.onchange = (event) => { commit('updateBreakpoint', { breakpoint: 'isTablet', value: event.matches }) }
			isTabletWide.onchange = (event) => { commit('updateBreakpoint', { breakpoint: 'isTabletWide', value: event.matches }) }
			isDesktop.onchange = (event) => { commit('updateBreakpoint', { breakpoint: 'isDesktop', value: event.matches }) }
			isDesktopWide.onchange = (event) => { commit('updateBreakpoint', { breakpoint: 'isDesktopWide', value: event.matches }) }

			if	(!isOnChangeSupported) {
				/** .addListener is marked as deprecated in MDN, but it's the only solution that works across OS and browsers. */
				isMobileOnly.addListener(isMobileOnly.onchange)
				isTablet.addListener(isTablet.onchange)
				isTabletWide.addListener(isTabletWide.onchange)
				isDesktop.addListener(isDesktop.onchange)
				isDesktopWide.addListener(isDesktopWide.onchange)
			}

			commit('updateBreakpoint', { breakpoint: 'isMobileOnly', value: !isMobileOnly.matches })
			commit('updateBreakpoint', { breakpoint: 'isTablet', value: isTablet.matches })
			commit('updateBreakpoint', { breakpoint: 'isTabletWide', value: isTabletWide.matches })
			commit('updateBreakpoint', { breakpoint: 'isDesktop', value: isDesktop.matches })
			commit('updateBreakpoint', { breakpoint: 'isDesktopWide', value: isDesktopWide.matches })
		}
	},
}
