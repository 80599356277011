import getCookies from "../plugins/getCookies.js"
import { UserType } from "~/assets/scripts/valueObjects/UserType"

const signInAppUsers = async (context) => {
	if (context.store.state.authentication.tokenInfo.isAuthenticated) return

	let mobiletoken = context.req?.headers?.authorization;
	if (!mobiletoken) {
		mobiletoken = context.req?.headers?.Authorization;
	}

	if (!mobiletoken) return

	context.$axios.setHeader("Authorization", mobiletoken)
	const response = await context.$axios.$get("auth/renewToken", { params: { async: true } })

	if (!response?.result) return
	response.result.isAppLogin = true
	context.store.dispatch("authentication/loginUser", response.result)
}

export default async function (context) {
	await signInAppUsers(context)
	await context.store.dispatch('updateFooterStyle', 'normal')

	if (!context.from || context.from.path === context.route.path) {
		getCookies(context)
	}

	const { fullPath } = context.route

	const { authentication } = context.store.state
	const businessFullPathExclude = [
		'/business/sign-in',
		'/business/forgot-password'
	]

	if (authentication.tokenInfo.isAuthenticated) {
		if ([UserType.Customer].includes(authentication.user?.userType)) {
			if (fullPath.startsWith('/business')) {
				context.error({ statusCode: 404, message: 'Unauthorized' })
			}
		} else if ([UserType.Agent, UserType.Corporate].includes(authentication.user?.userType)) {
			if (fullPath.startsWith('/my-account')) {
				context.error({ statusCode: 404, message: 'Unauthorized' })
			}
		}
	} else if (fullPath.startsWith('/business') && !businessFullPathExclude.some(path => fullPath.includes(path))) {
		context.redirect("/business/sign-in/")
	} else if (fullPath.startsWith('/my-account')) {
		context.redirect("/sign-in/")
	}
}
