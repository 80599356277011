import { UserType } from "~/assets/scripts/valueObjects/UserType"

const hasItineraryChanged = (context) => {
	const lastQueryDateTimeInHours = context.$date.diff(context.store.state.itinerary.lastQueryDateTime, new Date(), 'hours');
	if (lastQueryDateTimeInHours > 3) {
		return true;
	}
	const cookieInfo = context.$cookies.get("user");
	const stateInfo = context.store.state.authentication.user;

	if (!!cookieInfo && !!cookieInfo.userId) {
		return cookieInfo.userId !== stateInfo.userId && !stateInfo.isAppLogin;
	}

	if (!!stateInfo && !!stateInfo.userId && !stateInfo.isAppLogin) {
		return true;
	}

	return false;
}

export default function (context) {
	const { authentication } = context.store.state
	const { fullPath } = context.route
	if (!process.server
		&& !fullPath.includes("step=5")
		&& hasItineraryChanged(context)) {
		context.store.dispatch('sessionTimeOut/itineraryTimeout', () => { context.redirect("/vehicles/") });
	}

	if (authentication.tokenInfo.isAuthenticated) {
		if ([UserType.Agent, UserType.Corporate].includes(authentication.user?.userType)) {
			if (fullPath.startsWith('/book-now')) {
				context.redirect("/business/book-now/")
			}
		} else if ([UserType.Customer].includes(authentication.user?.userType)) {
			if (fullPath.startsWith('/business')) {
				context.redirect("/book-now/")
			}
		} else {
			context.error({ statusCode: 404, message: 'Unauthorized' })
		}
	} else if (fullPath.startsWith('/business')) {
		context.redirect("/book-now/")
	}
}
