const middleware = {}

middleware['allPages'] = require('../middleware/allPages.js')
middleware['allPages'] = middleware['allPages'].default || middleware['allPages']

middleware['bookNow'] = require('../middleware/bookNow.js')
middleware['bookNow'] = middleware['bookNow'].default || middleware['bookNow']

middleware['slugToLowercase'] = require('../middleware/slugToLowercase.js')
middleware['slugToLowercase'] = middleware['slugToLowercase'].default || middleware['slugToLowercase']

middleware['trailingSlash'] = require('../middleware/trailingSlash.js')
middleware['trailingSlash'] = middleware['trailingSlash'].default || middleware['trailingSlash']

middleware['updateFooterStyle'] = require('../middleware/updateFooterStyle.js')
middleware['updateFooterStyle'] = middleware['updateFooterStyle'].default || middleware['updateFooterStyle']

export default middleware
