<template>
	<div class="h-full">
		<nuxt />
	</div>
</template>

<script>
import { meta } from "~/assets/scripts/utilities/headInfo.js";

export default {
	metaInfo(vm) {
		return meta(vm)
	},
};
</script>
