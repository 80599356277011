import { currentNZTimeOffSet } from '~/assets/POC/currentNZTimeOffSet.js'

const today = new Date();
const unitList = new Map(
	Object.entries({
		seconds: 1000,
		minutes: 60000,
		hour: 3600000,
		days: 86400000,
		weeks: 604800000,
		years: 86400000 * 365,
		leapYears: 86400000 * 366,
	}),
);

/**
 * @param {string} format
 * @param {date object} date
 * @return {string}
 * ? https://vcalendar.io/i18n.html#mask-tokens
 */
const dateFormat = (format, date) => {
	if (!format || !date) {
		return "";
	}

	if (format === "DDDD D DDD HH:MM APM") {
		return date.toLocaleString("en-NZ", { weekday: "long", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" }); // Thursday, 20 Dec, 04:00 pm
	}

	if (format === "DDD D MMM YYYY") {
		return date.toLocaleString("en-NZ", { weekday: "short", year: "numeric", month: "short", day: "numeric" }); // Thu, 20 Dec 2012
	}

	if (format === "D MMM YYYY") {
		return date.toLocaleString("en-NZ", { day: "numeric", month: "short", year: "numeric" }); // 31 Dec 1980
	}

	if (format === "DD MM YYYY") {
		return date.toLocaleString("en-NZ", { day: "2-digit", month: "2-digit", year: "numeric" }); // 31/12/1980
	}

	if (format === "D MMMM") {
		return date.toLocaleString("en-NZ", { day: "numeric", month: "long" }); // 31 December
	}

	if (format === "YYYY-MM-DD") {
		const getYear 	= date.toLocaleString("en-NZ", { year: "numeric" })
		const getMonth 	= date.toLocaleString("en-NZ", { month: "2-digit" })
		const getDay 	= date.toLocaleString("en-NZ", { day: "2-digit" })
		return getYear + "-" + getMonth + "-" + getDay // 2020-08-21
	}

	if (format === "YYYYMMDD") {
		const getYear 	= date.toLocaleString("en-NZ", { year: "numeric" })
		const getMonth 	= date.toLocaleString("en-NZ", { month: "2-digit" })
		const getDay 	= date.toLocaleString("en-NZ", { day: "2-digit" })
		return getYear + getMonth + getDay // 20200821
	}
};

/**
 * @param {date object} date
 */
const isToday = (date) => {
	return dateFormat("YYYYMMDD", date) === dateFormat("YYYYMMDD", new Date());
};

/**
 *
 * @param {date object} date
 * @param {number} count
 * @param {dateUnit} unit
 */
const add = (date, count, unit) => {
	if (isDate(date) && !isNaN(count) && unitList.has(unit)) {
		const countMiliseconds = date.getTime();
		return new Date(countMiliseconds + count * unitList.get(unit));
	}

	throw new Error("add");
};

const diff = (start, end, unit) => {
	if (isDate(start) && isDate(end)) {
		const SECOND = 1000;
		const MINUTE = 60 * SECOND;
		const HOUR = 60 * MINUTE;
		const DAY = 24 * HOUR;
		switch (unit) {
			case 'seconds':
				return Math.abs(end.getTime() - start.getTime()) / SECOND;
			case 'minutes':
				return Math.abs(end.getTime() - start.getTime()) / MINUTE;
				break;
			case 'hours':
				return Math.abs(end.getTime() - start.getTime()) / HOUR;
			default:
				return Math.abs(end.getTime() - start.getTime()) / DAY;
		}
	}

	throw new Error("diff");
};

/**
 * @param {date object} datetime
 */
const toNZ = (dateObj) => {
	if (isDate(dateObj)) {
		const isWindowDefined = typeof window !== "undefined";
		const isNZDate = dateObj.toString().includes("New Zealand");

		if (isWindowDefined && !isNZDate) {
			const utcTime = dateObj.getTime() + dateObj.getTimezoneOffset() * 60000;
			const timeOffset = currentNZTimeOffSet;
			return new Date(utcTime + 3600000 * timeOffset);
		} else {
			return dateObj;
		}
	}

	throw new Error("toNZ");
};

/**
 * @param {*} date
 * @return {boolean}
 */
const isDate = (date) => {
	return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
};

/**
 * legacy
 * @param {string} dateString
 * @param {date config obj} config
 */
const timeModeToDate = (dateString, config) => {
	if (dateString) {
		let convertDate = new Date();
		const str = dateString.replace(/\//g, "-");
		if (str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/)) {
			const [day, month, year] = str.split("-");
			convertDate = new Date(year, month - 1, day);
		} else {
			convertDate = new Date(dateString);
		}

		if (!config) {
			return convertDate.toLocaleDateString("en-NZ", { day: "numeric", month: "short", year: "numeric" });
		} else {
			return convertDate.toLocaleDateString("en-NZ", config);
		}
	}
	return "";
};

export default {
	toNZ,
	isToday,
	isDate,
	today,
	timeModeToDate,
	format: dateFormat,
	add,
	diff
};
