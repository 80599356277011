/* eslint-disable no-useless-escape */
function detect() {
	const info = {
		device: null,
		os: null,
		browserName: null,
		browserVersion: null,
		browserMajorVersion: null,
		engine: null,
	};

	if (typeof window !== "undefined") {
		// script settings
		const browsersList = [
			["android", /Android\s([0-9\.]+)/],
			["aol", /AOLShield\/([0-9\._]+)/],
			["bb10", /BB10;\sTouch.*Version\/([0-9\.]+)/],
			["beaker", /BeakerBrowser\/([0-9\.]+)/],
			["chromium-webview", /(?!Chrom.*OPR)wv\).*Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
			["crios", /CriOS\/([0-9\.]+)(:?\s|$)/],
			["edge", /Edge\/([0-9\._]+)/],
			["edge-chromium", /Edg\/([0-9\.]+)/],
			["edge-ios", /EdgiOS\/([0-9\._]+)/],
			["facebook", /FBAV\/([0-9\.]+)/],
			["fxios", /FxiOS\/([0-9\.]+)/],
			["msie", /MSIE\s(7\.0)/],
			["msie", /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],
			["msie", /Trident\/7\.0.*rv\:([0-9\.]+).*\).*Gecko$/],
			["instagram", /Instagram\s([0-9\.]+)/],
			["ios", /Version\/([0-9\._]+).*Mobile.*Safari.*/],
			["ios-webview", /AppleWebKit\/([0-9\.]+).*Gecko\)$/],
			["ios-webview", /AppleWebKit\/([0-9\.]+).*Mobile/],
			["kakaotalk", /KAKAOTALK\s([0-9\.]+)/],
			["miui", /MiuiBrowser\/([0-9\.]+)$/],
			["opera", /Opera\/([0-9\.]+)(?:\s|$)/],
			["opera", /OPR\/([0-9\.]+)(:?\s|$)/],
			["opera-mini", /Opera Mini.*Version\/([0-9\.]+)/],
			["phantomjs", /PhantomJS\/([0-9\.]+)(:?\s|$)/],
			["samsung", /SamsungBrowser\/([0-9\.]+)/],
			["silk", /\bSilk\/([0-9._-]+)\b/],
			["vivaldi", /Vivaldi\/([0-9\.]+)/],
			["yandexbrowser", /YaBrowser\/([0-9\._]+)/],
			["searchbot", /alexa|bot|crawl(er|ing)|facebookexternalhit|feedburner|google web preview|nagios|postrank|pingdom|slurp|spider|yahoo!|yandex/],
			["safari", /Version\/([0-9\._]+).*Safari/],
			["firefox", /Firefox\/([0-9\.]+)(?:\s|$)/],
			["chrome", /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
		];

		const enginesList = [
			["gecko", /Gecko/],
			["presto", /Presto/],
			["trident", /(MSIE|Trident)/],
			["webkit", /AppleWebKit/],
		];

		const operatingSystemList = [["Amazon OS", /Kindle/], ["Android OS", /Android/], ["BeOS", /BeOS/], ["BlackBerry OS", /BlackBerry|BB10/], ["Chrome OS", /CrOS/], ["iOS", /iP(hone|od|ad)/], ["Linux", /(Linux)|(X11)/], ["Mac OS", /(Mac_PowerPC)|(Macintosh)/], ["Open BSD", /OpenBSD/], ["OS/2", /OS\/2/], ["QNX", /QNX/], ["Sun OS", /SunOS/], ["Windows 10", /(Windows NT 10.0)/], ["Windows 2000", /(Windows NT 5.0)|(Windows 2000)/], ["Windows 3.11", /Win16/], ["Windows 7", /(Windows NT 6.1)/], ["Windows 8", /(Windows NT 6.2)/], ["Windows 8.1", /(Windows NT 6.3)/], ["Windows 95", /(Windows 95)|(Win95)|(Windows_95)/], ["Windows 98", /(Windows 98)|(Win98)/], ["Windows ME", /Windows ME/], ["Windows Mobile", /IEMobile/], ["Windows Server 2003", /(Windows NT 5.2)/], ["Windows Vista", /(Windows NT 6.0)/], ["Windows XP", /(Windows NT 5.1)|(Windows XP)/][("Search Bot", /(nuhk)|(Googlebot)|(Yammybot)|(Openbot)|(Slurp)|(MSNBot)|(Ask Jeeves\/Teoma)|(ia_archiver)/)]];

		// private functions
		const detectItem = (userAgent, items) => {
			if (userAgent) {
				const itemsLength = items.length;

				for (let i = 0; i < itemsLength; i++) {
					const name = items[i][0];
					const value = items[i][1].exec(userAgent);

					if (value) {
						if (value[1]) {
							return {
								name,
								value: value[1],
							};
						} else {
							return {
								name,
								value: name,
							};
						}
					}
				}
			}

			return null;
		};

		const detectDevice = (os) => {
			if (os) {
				const isMobile = false || os === "Amazon OS" || os === "Android OS" || os === "BlackBerry OS" || os === "iOS" || os === "Windows Mobile";

				const isDesktop = false || os === "Amazon OS" || os === "BeOS" || os === "Chrome OS" || os === "Linux" || os === "Mac OS" || os === "Open BSD" || os === "OS/2" || os === "QNX" || os === "Sun OS" || os === "Windows 10" || os === "Windows 2000" || os === "Windows 3.11" || os === "Windows 7" || os === "Windows 8" || os === "Windows 8.1" || os === "Windows 95" || os === "Windows 98" || os === "Windows ME" || os === "Windows Server 2003" || os === "Windows Vista" || os === "Windows XP";

				if (isMobile) {
					return "mobile";
				}

				if (isDesktop) {
					return "desktop";
				}
			}

			return null;
		};

		// perform detection
		const ua = navigator.userAgent;

		const browser = detectItem(ua, browsersList);
		const engine = detectItem(ua, enginesList);
		const os = detectItem(ua, operatingSystemList);
		const device = detectDevice(os.name);

		info.browserName = browser.name;
		info.browserVersion = browser.value;
		info.browserMajorVersion = browser.value.split(".")[0];
		info.engine = engine;
		info.os = os;
		info.device = device;
	}

	return info;
}

// export detection information
export const browserDetect = detect();
