export default function ({ route, redirect }) {
    const path = route.path;
    const queryString = route.fullPath.replace(path, '');
    const isFilename = path.split('/').pop().includes('.');
    const hasTrailingSlash = path.endsWith('/');

    if (isFilename || hasTrailingSlash) {
      return;
    }

    const newPath = path + '/' + queryString;
    redirect(301, newPath);
  }
