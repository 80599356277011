<template>
	<div id="backToTop" class="flex flex-col min-h-full">
		<thirdPartyScripts />

		<client-only v-if="isLoading">
			<loadingBlockUI />
		</client-only>

		<mainHeader v-show="!hideHeader" class="relative z-500" />

		<div class="flex-grow flex-shrink-0 basis-auto grid grid-cols-1 auto-rows-auto">
			<nuxt />
		</div>

		<LazyHydrate v-if="$store.state.footerStyle === 'normal'" when-visible>
			<mainSitemap v-show="!hideHeader" class="flex-shrink-0" />
		</LazyHydrate>
		<mainFooter v-show="!hideHeader" class="flex-shrink-0" />
	</div>
</template>

<script>
import { headInfo } from "~/assets/scripts/utilities/headInfo.js";
import loadingBlockUI from "~/components/loadingBlockUI.vue"; // dont use lazy load for this
import mainHeader from "~/components/mainHeader.vue";
import mainSitemap from "~/components/mainSitemap.vue";
import thirdPartyScripts from "~/components/thirdPartyScripts.vue";

if (process.client) {
	window.__grHelpers = {
		webViewCallback(command) {
			if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.webViewCallback) {
				window.webkit.messageHandlers.webViewCallback.postMessage(command);
			}
		},
	};
}

export default {
	components: {
		loadingBlockUI,
		mainHeader,
		thirdPartyScripts,
		mainSitemap,
		mainFooter: () => import("~/components/mainFooter.vue"),
	},
	data() {
		return {
			eventSent: false,
			isWebview: this.$route.query?.mobileToken
		};
	},
	computed: {
		isLoading() {
			return !!this.$store.state.eventBus.loadingQueue.length;
		},
		hideHeader() {
			return this.$route.hash === "#hideheader" || this.$store.state.authentication.user.isAppLogin || this.$store.state.disableHeaderCommand;
		},
		showFormEmailUs(emailSent) {
			return this.$store.state.showFormEmailUs;
		},
		emailUs() {
			return import("~/components/modals/emailUs.vue");
		},
		emailConfirmation() {
			return import("~/components/modals/emailConfirmation.vue");
		},
	},
	watch: {
		$route() {
			// console.log('%c >> $route.path ' + this.$route.path, 'background: #222; color: #bada55')
			this.$modalService.close(); // close modal when route change
		},
		showFormEmailUs(newValue, oldValue) {
			if (newValue) {
				const self = this;
				this.emailUs.then((emailUs) => {
					self.$modalService
						.create(emailUs.default)
						.on("ok", () => {
							self.toggleFormEmailUs();
							this.emailConfirmation.then((emailConfirmation) => {
								self.$modalService.create(emailConfirmation.default).show();
							});
						})
						.on("close", () => {
							if (this.$store.state.showFormEmailUs) {
								self.toggleFormEmailUs();
							}
						})
						.show();
				});
			}
		},
	},
	methods: {
		toggleFormEmailUs(emailSent) {
			this.$store.commit("toggleFormEmailUs");
		},
	},
	metaInfo(vm) {
		return headInfo(vm)
	},
};
</script>
