import Vue from "vue"; // eslint-disable-line

Vue.mixin({
	methods: {
		getPlural(words, value) {
			return value > 1 ? words[1] : words[0];
		},
		$scrollTo(target, offset = -120) {
			this.$nextTick(() => {
				const element = false
					|| document.querySelectorAll(`[scroll-target="${target}"]`)[0]
					|| document.querySelectorAll(`[scrollTarget="${target}"]`)[0]
					|| document.getElementById(target)
					|| document.body;

				const top = element.getBoundingClientRect().top + window.pageYOffset + offset;
				setTimeout(() => {
					window.scrollTo({ top, behavior: 'smooth' })
				}, 10)
			});
		},
		$nowNZ () {
			// Create a new Date object in the local time zone.
			const localDate = new Date();

			// Format the Date object to a string in Japanese (ja-JP), with the time in the Pacific/Auckland time zone.
			// Japanese string format '2023/10/1 21:07:11' notice year in front
			const pacificAucklandDateStr = localDate.toLocaleString("ja-JP", { timeZone: "Pacific/Auckland" });

			// Create a new Date object from the string representation of the date in the Pacific/Auckland time zone.
			const pacificAucklandDate = new Date(pacificAucklandDateStr);
			return new Date(pacificAucklandDate)
		}
	},
});
