export const state = () => ({
	reviews: [],
	branchReviews: [],
})

export const getters = {
	byPlatforms: state =>  platforms => {
		if (!platforms) throw new Error('No platforms provided')
		const filterByPlatform = (review) => platforms.includes(review.platform)
		const sortByPlatform = (a, b) => platforms.indexOf(a.platform) - platforms.indexOf(b.platform)
		if (!state.reviews.length) return []
		return state.reviews.filter(filterByPlatform).sort(sortByPlatform)
	},
	branchBySlug: state => slug => {
		if (!slug) throw new Error('No slug provided')
		if (!state.branchReviews.length) { return null }
		return state.branchReviews.find(branch => branch.slug === slug)
	}
}

export const mutations = {
	setReviews(state, reviews) {
		state.reviews = reviews
	},
	setBranchReviews(state, branchReviews) {
		state.branchReviews = branchReviews
	},
}

export const actions = {
	async fetchCompany({ state, commit }, { brandName = "GO Rentals" } = {}) {
		if (state.reviews.length) return state.reviews
		const endpoint = ""
			+ '/company-reviews/'
			+ '?' + 'filters[brand][$eqi]=' + brandName

		const { data: strapiReviews } = await this.$strapi.$get(endpoint)
			.catch(err => ({ data: [] }))

		const reviews = strapiReviews.map(r => r.attributes)
		commit("setReviews", reviews)
		return reviews
	},

	async fetchBranch({ state, commit }, { brandName = "GO Rentals" } = {}) {
		if (state.branchReviews.length) return state.branchReviews
		const endpoint = ""
			+ '/branch-reviews/'
			+ '?' + 'filters[brand][$eqi]=' + brandName
			+ '&' + 'populate=*'

		const { data: strapiBranchReviews } = await this.$strapi.$get(endpoint)
			.catch(err => ({ data: [] }))
		
		const branchReviews = strapiBranchReviews.map(r => r.attributes)
		commit("setBranchReviews", branchReviews)
		return branchReviews
	},

	async fetchBranchByCode({ state, commit }, { brandName = "GO Rentals", branchCode } = {}) {
		const endpoint = ""
		+ '/branch-reviews/'
		+ '?' + 'populate=*'
		+ '&' + 'filters[brand][$eqi]=' + brandName
		+ '&' + 'filters[branchCode][$eqi]=' + branchCode
		
		const { data: strapiBranchReviews } = await this.$strapi.$get(endpoint)
			.catch(err => ({ data: [] }))

		const branchReview = strapiBranchReviews.find(r => r.attributes.branchCode === branchCode)?.attributes || null
		return branchReview
	},
}
