export const getVehicleClassCodeImageAlt = {
	"GROUP-1-0": "Image of blue MG3 rental car parked outside a building with pink GO Rentals plates",
	"GROUP-1-1": "Side image of a silver MG3 rental car on a white background ",
	"GROUP-1-2": "Rear image of a silver MG3 rental car on a white background",
	"GROUP-1-3": "Interior image of an MG3 rental car taken from back seat looking at modern dashboard and entertainment system",
	"GROUP-1-4": "Interior image of the back seats of an MG3 rental car",
	"GROUP-10-0": "Image of a Hyundai iMax People Carrier parked up at a New Zealand vineyard with rows of vines in the background",
	"GROUP-10-1": "Side image of a silver Hyundai iMax People Carrier on a white background",
	"GROUP-10-2": "Side and rear image of a silver Hyundai iMax People Carrier on a white background",
	"GROUP-10-3": "Interior image of an Hyundai iMax People Carrier taken from driver's seat",
	"GROUP-10-4": "Interior image of an Hyundai iMax People Carrier showing the two rows of 3 backseats in this 8-seater people carrier",
	"GROUP-11-0": "Side image of a Toyota Hiace 10-seater minibus parked in a New Zealand park next to the grass under a tree",
	"GROUP-11-1": "Front image of a Toyota Hiace 10-seater minibus on a white background",
	"GROUP-11-2": "Rear image of a white Toyota Hiace 10-seater minibus on a white background with a GO Rentals plate",
	"GROUP-11-3": "Interior image of the front of the Toyota Hiace 10-seater minibus taken from the rear seats",
	"GROUP-11-4": "Interior image of the rear of the Toyota Hiace 10-seater minibus",
	"GROUP-12-0": "Front image of a white Toyota Hiace 12-seater minibus parked up with trees in the background",
	"GROUP-12-1": "Side image of a white Toyota Hiace 12-seater minibus on a white background",
	"GROUP-12-2": "Rear image of a white Toyota Hiace 12-seater minibus on a white background with GO Rentals plates",
	"GROUP-12-3": "Interior image of a Toyota Hiace 12-seater minibus showing the front dashboard and a view through the windscreen",
	"GROUP-12-4": "Rear interior image of a Toyota Hiace 12-seater minibus showing the backseats",
	"GROUP-13-0": "Front image of an Hyundai Santa Fe parked near a beach with the sea and a cliff in the background with pink GO Rentals plates",
	"GROUP-13-1": "Front image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-13-2": "Side image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-13-3": "Front and side image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-14-0": "Front image of a Toyota Land Cruiser Prado 4x4 rental car parked on a grassy mountainside with a view of a lake in the distance",
	"GROUP-14-1": "Front image of a Toyota Land Cruiser Prado 4x4 rental car on a white background",
	"GROUP-14-2": "Rear image of a silver Toyota Land Cruise Prado 4x4 rental car on a white background",
	"GROUP-14-3": "Interior image of the front of a Toyota Land Cruiser Prado 4x4 rental car showing the dashboard and entertainment system",
	"GROUP-14-4": "Interior rear image of a Toyota Land Cruiser Prado 4x4 rental car showing back seats",
	"GROUP-15-0": "Side image of a Toyota Corolla Wagon rental car on a white background",
	"GROUP-15-1": "Front image of a Toyota Corolla Wagon rental car on a white background",
	"GROUP-15-2": "Front and side image of a Toyota Corolla Wagon rental car on a white background",
	"GROUP-15-3": "Side image of a silver Toyota Corolla Wagon rental car on a white background",
	"GROUP-16-0": "Front image of a Toyota Land Cruiser Prado 4x4 rental car parked on a grassy mountainside with a view of a lake in the distance",
	"GROUP-16-1": "Front image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-16-2": "Side image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-16-3": "Front and side image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-1M-0": "Front image of an electric blue Toyota Yaris on a white background",
	"GROUP-1M-1": "Front and side image of an electric blue Toyota Yaris on a white background",
	"GROUP-1M-2": "Side image of an electric blue Toyota Yaris rental car on a white background",
	"GROUP-1M-3": "Front image of an electric blue Toyota Yaris rental car on a white background",
	"GROUP-2-0": "Front image of a red Toyota Corolla rental car parked up next to a river on a blue sky day",
	"GROUP-2-1": "Front image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-2-2": "Rear image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-2-3": "Interior front image of a Toyota Corolla rental car showing the dashboard and entertainment system",
	"GROUP-2-4": "Interior rear image of a Toyota Corolla rental car showing the back seats",
	"GROUP-2M-0": "Front image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-2M-1": "Side image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-2M-2": "Front and side image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-2M-3": "Front and side image of an electric blue Toyota Corolla hatchback rental car on a white background",
	"GROUP-3-0": "Side image of a Toyota Corolla Touring sedan rental car parked up on a road at dusk with mountains in the background",
	"GROUP-3-1": "Front and side image of a Toyota Corolla Touring sedan on a white background",
	"GROUP-3-2": "Rear image of a Toyota Corolla Touring sedan rental car on a white background",
	"GROUP-3-3": "Interior front image of a Toyota Corolla touring sedan showing the dashboard and entertainment system",
	"GROUP-3-4": "Interior rear image of a Toyota Corolla touring sedan showing the back seats",
	"GROUP-3E-0": "",
	"GROUP-3E-1": "",
	"GROUP-3E-2": "",
	"GROUP-3E-3": "",
	"GROUP-3E-4": "",
	"GROUP-4-0": "Stylised front image of a Toyota CHR parked up down a lane with GO Rentals plates",
	"GROUP-4-1": "Front image of a Toyota CHR on a white background",
	"GROUP-4-2": "Rear image of a Toyota CHR Kona on a white background",
	"GROUP-4-3": "Interior front image of a Toyota CHR rental car showing the dashboard and entertainment system",
	"GROUP-4-4": "Interior rear image of a Toyota CHR rental car showing the back sears with one in fold down position",
	"GROUP-4H-0": "Exterior side image of a Toyota Camry Hybrid parked up with a view looking down over a turquoise lake with a man taking pictures",
	"GROUP-4H-1": "Side image of a blue Toyota Camry Hybrid on a white background",
	"GROUP-4H-2": "Rear image of a blue Toyota Camry Hybrid on a white background",
	"GROUP-4H-3": "Interior front  image of a Toyota Camry Hybrid rental car showing the dashboard and entertainment system",
	"GROUP-4H-4": "Interior rear image of a Toyota Camry Hybrid rental car showing the backseats with one seat in fold down position",
	"GROUP-5-0": "Exterior front image of a blue Hyundai Tucson SUV with the sea in the background",
	"GROUP-5-1": "Side image of a silver Hyundai Tucson on a white background",
	"GROUP-5-2": "Rear image of a silver Hyundai Tucson on a white background",
	"GROUP-5-3": "Interior front image of an Hyundai Tucson showing the dashboard and entertainment system",
	"GROUP-5-4": "Interior front image of a Hyundai Tucson SUV looking back across the front seats",
	"GROUP-6-0": "Exterior image of a Toyota Rav4 rental car parked off-road with snow-capped mountains in the backdrop",
	"GROUP-6-1": "Front image of a Toyota Rav4 rental car on a white background",
	"GROUP-6-2": "Rear and side image of a Toyota Rav4 rental car on a white background",
	"GROUP-6-3": "Interior image of the front of a Toyota Rav4 showing the dashboard and entertainment system",
	"GROUP-6-4": "Interior image of the rear of a Toyota Rav4 showing the back seats",
	"GROUP-7-0": "Exterior image of a grey Hyundai Santa Fe parked off-road with snow-capped mountains in the background",
	"GROUP-7-1": "Side image of a grey Hyundai Santa Fe on a white background",
	"GROUP-7-2": "Rear image of a grey Hyundai Santa Fe SUV on a white background",
	"GROUP-7-3": "Interior image of the front of an Hyundai Santa Fe SUV showing the dashboard and entertainment system",
	"GROUP-7-4": "Interior image of the rear of an Hyundai Santa Fe SUV showing two back seats in fold down position",
	"GROUP-8-0": "Exterior image of a red Hyundai Santa Fe parked outside a wooden building with trees in the background",
	"GROUP-8-1": "Front and side image of a white Hyundai Santa Fe SUV on a white background",
	"GROUP-8-2": "Rear image of a white Hyundai Santa Fe SUV on a white background",
	"GROUP-8-3": "Interior image of the front of an Hyundai Santa Fe SUV showing the dashboard and entertainment system",
	"GROUP-8-4": "Interior image of the rear of an Hyundai Santa Fe SUV showing two back seats in fold down position",
	"GROUP-9-0": "Exterior Image of a red Toyota Hilux 4x4 parked at a builders yard with piles of wood in background",
	"GROUP-9-1": "Front and side image of a white Toyota Hilux 4x4 on a white background",
	"GROUP-9-2": "Rear and side image of a white Toyota Hilux 4x4 on a white background",
	"GROUP-9-3": "Interior image of the front of a Toyota Hilux 4x4 showing the dashboard and entertainment system",
	"GROUP-9-4": "Interior image of the rear of a Toyota Hilux 4x4 showing the back seats",
	"GROUP-A-0": "Exterior image of a silver Toyota Yaris parked up by the side of a lake with mountains in the backdrop",
	"GROUP-A-1": "Front and side image of a silver Toyota Yaris on a white background",
	"GROUP-A-2": "Rear image of a silver Toyota Yaris on a white background",
	"GROUP-A-3": "Interior image of the front of a Toyota Yaris showing the dashboard and entertainment system",
	"GROUP-A-4": "Interior image of the rear of a Toyota Yaris showing the back seats",
	"GROUP-AM-0": "Exterior image of a silver Toyota Yaris driving down a tree lined lane with headlights on",
	"GROUP-AM-1": "Side and front image of an electric blue Toyota Yaris on a white background",
	"GROUP-AM-2": "Rear image of an electric blue Toyota Yaris on a white background",
	"GROUP-AM-3": "Interior image of the front of a Toyota Yaris showing the dashboard and entertainment system",
	"GROUP-AM-4": "Interior image of the rear of a Toyota Yaris showing the back seats",
	"GROUP-B-0": "Exterior image of a red Toyota Corolla hatchback rental car driving on an open road with mountains in the background",
	"GROUP-B-1": "Side and front image of a white Toyota Corolla hatchback rental car on a white background",
	"GROUP-B-2": "Rear image of a white Toyota Corolla hatchback rental car on a white background",
	"GROUP-B-3": "Interior image of the front of a Toyota Corolla hatchback showing the dashboard and entertainment system",
	"GROUP-B-4": "Interior image of the rear of a Toyota Corolla hatchback showing the back seats",
	"GROUP-BM-0": "Exterior image of a white Toyota Corolla hatchback rental car parked on Wellington waterfront with a man standing by the driver's door",
	"GROUP-BM-1": "Side and front image of a silver Toyota Corolla hatchback on a white background",
	"GROUP-BM-2": "Rear image of a white Toyota Corolla hatchback rental car on a white background",
	"GROUP-BM-3": "Interior image of the front of a Toyota Corolla hatchback showing the dashboard and entertainment system",
	"GROUP-BM-4": "Interior image of the rear of a Toyota Corolla hatchback showing the back seats",
	"GROUP-C-0": "Exterior image of a Toyota Corolla Touring sedan parked next to a lake with two people leaning on the boot",
	"GROUP-C-1": "Front and side image of a silver Toyota Corolla Touring sedan on a white background",
	"GROUP-C-2": "Rear image of a silver Toyota Corolla Touring sedan on a white background",
	"GROUP-C-3": "Interior image of the front of a Toyota Corolla Touring sedan showing the dashboard and entertainment system",
	"GROUP-C-4": "Interior image of the rear of a Toyota Corolla Touring sedan showing the back seats",
	"GROUP-D-0": "Exterior image of a silver Toyota Aurion parked under a tree next to the sea",
	"GROUP-D-1": "Side and front image of a silver Toyota Aurion on a white background",
	"GROUP-D-2": "Rear image of a silver Toyota Aurion on a white background",
	"GROUP-D-3": "Interior image of the front of a Toyota Aurion showing the dashboard and entertainment system",
	"GROUP-D-4": "Interior image of the rear of a Toyota Aurion showing the back seats",
	"GROUP-E-0": "Front and side image of a Toyota Wagon on a white background",
	"GROUP-E-1": "Side and front image of a Toyota Wagon on a white background",
	"GROUP-E-2": "Front image of a Toyota Wagon rental car on a white background",
	"GROUP-E-3": "Side image of a Toyota Wagon rental car on a white background",
	"GROUP-F-0": "Side image of white Toyota Previa People Mover on a white background",
	"GROUP-F-1": "Front image of a white Toyota Previa People Mover on a white background",
	"GROUP-F-2": "Front and side image of a white Toyota Previa People Mover on a white background",
	"GROUP-F-3": "Side and front image of a white Toyota Previa People Mover on a white background",
	"GROUP-G-0": "Side image of white Toyota Rav4 SUV on a white background",
	"GROUP-G-1": "Front image of white Toyota Rav4 SUV on a white background",
	"GROUP-G-2": "Side and front image of white Toyota Rav4 SUV on a white background",
	"GROUP-G-3": "Front and side image of white Toyota Rav4 SUV on a white background",
	"GROUP-M-0": "Exterior image of the GO Glamper trailer parked up with the backdoor open and two camping chairs outside with a beautiful twilight sky",
	"GROUP-M-1": "Side image of the GO Glamper trailer on a white background",
	"GROUP-M-2": "Interior image of the GO Glamper trailer showing the full sized queen bed",
	"GROUP-M-3": "Interior image of the GO Glamper trailer showing the kitchen area and dining table",
	"GROUP-M-4": "Interior image of the GO Glamper trailer showing the sink and fridge",
	"GROUP-S-0": "Image of a mystery rental car with a question mark on an outline of a vehicle",
	"GROUP-S-1": "Image of a mystery rental car with a question mark on an outline of a vehicle",
	"GROUP-S-2": "Image of a mystery rental car with a question mark on an outline of a vehicle",
	"GROUP-S-3": "Image of a mystery rental car with a question mark on an outline of a vehicle",
	"GROUP-T-0": "Image of a GO Rentals trailer which is available for hire on a white background",
	"GROUP-T-1": "Close up image of a GO Rentals trailer which is available for rental on a white background",
	"GROUP-T-2": "Side image of a GO Rentals trailer which is available for hire on a white background",
	"GROUP-T-3": "Front image of a GO Rentals trailer which is available for hire on a white background",
	"GROUP-T-4": "Front and side image of a GO Rentals trailer which is available for hire on a white background",
	"GROUP-X-0": "Image of a mystery rental car with a question mark on an outline of a vehicle",
	"GROUP-X-1": "Image of a mystery rental car with a question mark on an outline of a vehicle",
	"GROUP-X-2": "Image of a mystery rental car with a question mark on an outline of a vehicle",
	"GROUP-X-3": "Image of a mystery rental car with a question mark on an outline of a vehicle",
};
