import { toFixedTwoString } from './toFixedTwo'

export function isEmptyObject(obj) {
	obj = obj || {};
	return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function formatCurrency(number) {
	number = parseFloat(number);
	const result = number % 1 !== 0
		? "$" + toFixedTwoString(number).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
		: "$" + number;
	return result;
}

export function calculateAge(dateFrom, dateTo) {
	if (!dateFrom) return null;

	let dob = new Date();
	const str = dateFrom.replace(/\//g, "-");
	if (str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/)) {
		const [day, month, year] = str.split("-");
		dob = new Date(year, month - 1, day);
	} else {
		dob = new Date(dateFrom);
	}

	dateTo = dateTo ? new Date(dateTo) : new Date();
	const diff = dateTo.getTime() - dob.getTime();
	const age = new Date(diff);
	return Math.abs(age.getUTCFullYear() - 1970);
}

export function isPickupAfterHours(itinerary) {
	const { openingTime, closingTime } = itinerary.pickup.branch
	const pickupTime24 = itinerary.pickup.time.num24
	const pickupOpeningTime24 = Number(openingTime.replace(':', ''))
	let pickupClosingTime24 = Number(closingTime.replace(':', ''))
	if (pickupClosingTime24 < pickupOpeningTime24) {
		pickupClosingTime24 = 2400 + pickupClosingTime24
	}

	return !(pickupTime24 >= pickupOpeningTime24 && pickupTime24 <= pickupClosingTime24)
}

export function hexToRgb(hex) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	if (!result) return null
	return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
}
