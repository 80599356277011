const text24 = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"];
const text12 = ["12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"];

export const locationRange = timeArr => {
	return timeArr.map((text24, index) => {
		// return  {
		// 	index: 7,
		// 	text12: "03:30 AM",
		// 	text24: "03:30",
		// };
	
		return {
			index,
			num24: parseInt(text24.split(":").join("")),
			text12: text12[index],
			text24,
		};
	});
}

const range = locationRange(text24);

/**
 * @param {key} timeObj's key
 * @param {value} string
 */
const findBy = (key, value) => {
	return range.find((time) => time[key] === value)
};

/**
 * @param {timeObj} timeObj
 * @param {timeRange} timeRange
 */
const isInRange = (timeObj, timeRange) => {
	return timeRange.some((time) => time.index === timeObj.index);
};

/**
 * @param {string} format
 * @param {string = "HH:MM" | date object} time
 * @return {string}
 */
const timeFormat = (format, time) => {
	if (!format || !time) {
		return "";
	}

	// prettier-ignore
	time = typeof time === "string" || time instanceof String
		? new Date("01/01/1980 " + time)
		: time;

	if (format === "H:MM APM") {
		const options = { hour: "numeric", minute: "2-digit", hour12: true };
		return time.toLocaleTimeString("en-NZ", options).toUpperCase(); // 6:45 PM
	}

	if (format === "HH:MM APM") {
		const options = { hour: "2-digit", minute: "2-digit", hour12: true };
		return time.toLocaleTimeString("en-NZ", options).toUpperCase(); // 06:45 PM
	}

	if (format === "HH:MM") {
		const options = { hour: "2-digit", minute: "2-digit", hour12: false };
		return time.toLocaleTimeString("en-NZ", options).toUpperCase(); // 18:45
	}

	if (format === "HHMM") {
		const options = { hour: "2-digit", minute: "2-digit", hour12: false };
		return time.toLocaleTimeString("en-NZ", options).split(":").join(""); // 1845
	}

	if (format === "HH") {
		const options = { hour: "2-digit", hour12: false };
		return time.toLocaleTimeString("en-NZ", options).split(":").join(""); // 18
	}

	if (format === "MM") {
		const options = { minute: "2-digit", hour12: false };
		return time.toLocaleTimeString("en-NZ", options).split(":").join(""); // 45
	}
};

export default {
	range,
	text12,
	text24,
	isInRange,
	format: timeFormat,
	findBy,
};
