export default (context) => ({
	/**
	 * getImages base on https://image.nuxtjs.org/advanced/custom-provider
	 * url format based on https://developers.cloudflare.com/images/image-resizing
	 */
	getImage(src, modifiers = {}) {
		if (!src) throw new Error("No src provided")
		if (!context.$config.cloudFlareResizePath) return src

		const defaultModifiers = {
			format: 'auto',
		}

		const newSrc = src.includes('http')
			? src
			: src.charAt(0) === "/"
				? context.$config.hostUrl + src.substring(1)
				: context.$config.hostUrl + src

		const options = { ...defaultModifiers, ...modifiers }
		const modifiersToString = Object.entries(options).map(([key, value]) => `${key}=${value}`).join(',')
		return `${context.$config.cloudFlareResizePath}${modifiersToString}/${newSrc}`
	},
})
