export default {
	set(name, value, options) {
		if (process.browser) {
			sessionStorage.setItem(name, JSON.stringify(value));
		}
	},

	get(name) {
		if (process.browser) {
			return JSON.parse(sessionStorage.getItem(name));
		}

		return "";
	},

	remove(name) {
		if (process.browser) {
			sessionStorage.removeItem(name);
		}
	},
};
