const cookieMaxAge = 60 * 30;
export const state = () => ({
	reservation: {
		id: "",
		insurance: {},
		extras: [],
		carClassId: null,
		isCarbonOffset: false,
		otherFees: [],
		customerDetails: {},
		selectedShuttle: {},
		salesSource: null,
		processingFee: 0,
		travelReason: 'Leisure',
		mainCreditCard: null,
		agent: {
			isAllInclusive: false,
		},
	},
});

export const mutations = {
	updateReservation(state, reservation) {
		state.reservation = JSON.parse(JSON.stringify(reservation));
		const cookieObject = JSON.parse(JSON.stringify(reservation));
		const extras = state.reservation.extras;
		delete cookieObject.extras;
		this.$sessionStorageService.set("reservation", cookieObject, { path: "/", maxAge: cookieMaxAge });
		this.$sessionStorageService.set("reservation-extras", extras, { path: "/", maxAge: cookieMaxAge });
	},
	resetReservation(state) {
		const reservation = {
			insurance: {},
			extras: [],
			carClassId: null,
			isCarbonOffset: false,
			processingFee: 0,
			otherFees: [],
			customerDetails: {},
			selectedShuttle: {},
			travelReason: 'Leisure',
			mainCreditCard: null,
			agent: {},
		};
		state.reservation = JSON.parse(JSON.stringify(reservation));
		const cookieObject = JSON.parse(JSON.stringify(reservation));
		const extras = state.reservation.extras;
		delete cookieObject.extras;
		this.$sessionStorageService.set("reservation", cookieObject, { path: "/", maxAge: cookieMaxAge });
		this.$sessionStorageService.set("reservation-extras", extras, { path: "/", maxAge: cookieMaxAge });
	},
	resetReservationForChangeVehicle(state) {
		const reservation = JSON.parse(JSON.stringify(state.reservation));
		reservation.extras = [];
		reservation.otherFees = [];
		reservation.carClassId = null;
		reservation.processingFee = 0;
		reservation.insurance = {};
		reservation.stepComplete = { index: 1 };
		state.reservation = JSON.parse(JSON.stringify(reservation));
		const cookieObject = JSON.parse(JSON.stringify(reservation));
		const extras = state.reservation.extras;
		delete cookieObject.extras;
		this.$sessionStorageService.set("reservation", cookieObject, { path: "/", maxAge: cookieMaxAge });
		this.$sessionStorageService.set("reservation-extras", extras, { path: "/", maxAge: cookieMaxAge });
	},
};

export const actions = {};
