import { CarClassStatus } from "~/assets/scripts/valueObjects/CarClassStatus";
import { getSlug } from "~/assets/scripts/utilities/getSlug";
import { groups as vehicleGroups } from "~/static/data/vehiclesPage.js";
import { getVehiclesImages } from "~/assets/scripts/utilities/getVehiclesImages";

const cookieMaxAge = 60 * 30;

const getSortedVehicles = (vehicles) => {
	const byVehicleOrder = (a, b) => a.vehicleType.vehicleOrder - b.vehicleType.vehicleOrder
	const sorted = [...vehicles].sort(byVehicleOrder)
	const isSoldOut 	= (v) => v.carClassStatus === CarClassStatus.soldOut
	const isHotDeal 	= (v) => !isSoldOut(v) && !!v.hotDealInfo
	const isAvailable 	= (v) => !isHotDeal(v) && v.carClassStatus === CarClassStatus.available
	const isOnRequest 	= (v) => !isHotDeal(v) && v.carClassStatus === CarClassStatus.onRequest
	const deals 		= sorted.filter(isHotDeal)
	const available 	= sorted.filter(isAvailable)
	const onRequest 	= sorted.filter(isOnRequest)
	const soldOut 		= sorted.filter(isSoldOut)
	return [...deals, ...available, ...onRequest, ...soldOut]
}

export const state = () => ({
	list: [],
	deals: [],
	selectedVehicle: {
		carClassId: 0,
	},
	currentQuery: null,
	currentDealQuery: null,
	isLoading: false,
});

export const getters = {
	hotDeals: (state) => {
		return getSortedVehicles(state.deals.filter((deal) => deal.hotDealInfo.dealType === "HotDeal"))
	},
	reloDeals: (state) => {
		return getSortedVehicles(state.deals.filter((deal) => deal.hotDealInfo.dealType === "Relocation"))
	},
	dealsByClassCode: (state) => (classCode) => {
		if (!classCode) return []
		return state.deals.filter((deal) => deal.classCode === classCode)
	},
	typeGroups: (state) => {
		// Get the amount of available vehicles per vehicle type
		const groups = state.list.reduce((acc, vehicle) => {
			const { name } = vehicle.vehicleType
			const amount = vehicle.carClassStatus === CarClassStatus.available ? 1 : 0
			acc[name] = (acc[name] || 0) + amount
			return acc
		}, {})

		// Move groups with 0 available vehicles to the end
		const sortedGroupNames = Object.keys(groups).reduce((acc, groupName) => {
			const amount = groups[groupName]
			if (amount > 0) {
				acc.available.push(groupName)
			} else {
				acc.unAvailable.push(groupName)
			}
			return acc
		}, { available: [], unAvailable: [] })

		return sortedGroupNames.available.concat(sortedGroupNames.unAvailable)
	},
	groupedByType: (state, getters) => {
		return getters.typeGroups
			.map(vehicleType => state.list.filter(car => car.vehicleType.name === vehicleType))
			.map(getSortedVehicles)
	},
	groups: (state, getters) => {
		return getters.typeGroups.map(g => {
			const slug = getSlug(g)
			return vehicleGroups[slug]
		})
	},
	grouped: (state, getters) => {
		return getters.groupedByType.filter((v) => v);
	},
	ungrouped: (state, getters) => {
		return getSortedVehicles(state.list)
	},
};

export const mutations = {
	updateList(state, vehicles) {
		state.list = vehicles;
		this.commit("vehicle/resetSelectedVehicle");
	},
	updateSelectedVehicle(state, selectedVehicle) {
		state.selectedVehicle = selectedVehicle;
		this.$sessionStorageService.set("selectedVehicle", selectedVehicle, { path: "/", maxAge: cookieMaxAge });
	},
	resetSelectedVehicle(state) {
		const selectedVehicle = {
			carClassId: 0,
		};
		state.selectedVehicle = selectedVehicle;
		this.commit("booking/resetReservationForChangeVehicle");
		this.$sessionStorageService.set("selectedVehicle", selectedVehicle, { path: "/", maxAge: cookieMaxAge });
	},
	updateDeals(state, deals) {
		state.deals = deals;
	},
	updateCurrentQuery(state, currentQuery) {
		state.currentQuery = JSON.stringify(currentQuery);
	},
	updateCurrentDealQuery(state, currentDealQuery) {
		state.currentDealQuery = JSON.stringify(currentDealQuery);
	},
	updateIsLoading(state, isLoading) {
		state.isLoading = isLoading;
	},
};

export const actions = {
	fetchAll({ state, getters, commit, dispatch, rootState }, { itinerary, type = "vehicle" }) {
		const isEmpty = (itinerary) => {
			// prettier-ignore
			return false ||
				!itinerary ||
				Object.keys(itinerary).length === 0 ||
				!itinerary.pickup ||
				!itinerary.pickup.branch ||
				!itinerary.pickup.date ||
				!itinerary.pickup.time ||
				!itinerary.dropOff ||
				!itinerary.dropOff.branch ||
				!itinerary.dropOff.date ||
				!itinerary.dropOff.time
		};

		const addPicture = (v) => {
			v.picture = getVehiclesImages({ vehicle: v, cdnUrl: this.$config.cdnUrl })

			return v;
		};

		const renameFuelType = (v) => {
			if (v.fuelType) {
				const type = v.fuelType.toLowerCase();
				v.fuelType = ["unleaded", "unleaded 91"].includes(type)
					? "Petrol"
					: type.charAt(0).toUpperCase() + type.slice(1);
			}

			return v;
		};

		const updateOrderOfClassX = (v) => {
			if (v.classCode === "X") {
				v.vehicleType.vehicleOrder = 9999;
			}
		};

		const capitalizeVehicleTypeName = (v) => {
			v.vehicleType.name = v.vehicleType.name
				.toLowerCase()
				.replace(/^[a-z]/, match => match.toUpperCase()) // Capitalize first letter
				.replace('/ suvs', '/ SUVs') // Exceptions
				.replace('4 w', '4 W') // Exceptions
			return v
		};

		const removeSoldOutIfPromoCode = (vehicles) => {
			// prettier-ignore
			return !isEmpty(itinerary) && itinerary.promoCode ?
				vehicles.filter(v => v.carClassStatus !== CarClassStatus.soldOut) :
				vehicles;
		};

		const filterDeals = (vehicles) => {
			return vehicles.filter((v) => !!v.hotDealInfo);
		};

		const betterBranchNameComma = (v) => {
			v.hotDealInfo.doBranchName = v.hotDealInfo.doBranchName.split(",").join(", ");
			v.hotDealInfo.puBranchName = v.hotDealInfo.puBranchName.split(",").join(", ");
			return v;
		};

		const fetchVehicles = (query) => {
			const headers = !rootState.authentication.tokenInfo.isAuthenticated
				? {}
				: { Authorization: "Bearer " + rootState.authentication.tokenInfo.token };

			const response = !query || !Object.keys(query).length
				? this.$axios.$get("/booking/unquotedSearch")
				: this.$axios.$post("/booking/search", query, { headers })

			return response
				.then(this.$error.getResponseError)
				.then((response) => response.result)
				.then(removeSoldOutIfPromoCode)
				.then((vehicles) => {
					return vehicles.map((v) => {
						addPicture(v);
						renameFuelType(v);
						updateOrderOfClassX(v);
						capitalizeVehicleTypeName(v);

						return v;
					});
				});
		};

		const fetchDeals = (query) => {
			const dealQuery = {
				...query,
				agentId: null, // send null to get expected results
				dealTypes: "hotdeal,relocation",
			};

			const headers = !rootState.authentication.tokenInfo.isAuthenticated
				? {}
				: { Authorization: "Bearer " + rootState.authentication.tokenInfo.token };

			const response = !query || !Object.keys(query).length
				? this.$axios.$get("/booking/unquotedDealsSearch")
				: this.$axios.$post("/booking/search", dealQuery, { headers });

			return response
				.then(this.$error.getResponseError)
				.then((response) => response.result)
				.then(filterDeals)
				.then(removeSoldOutIfPromoCode)
				.then((vehicles) => {
					return vehicles.map((v) => {
						betterBranchNameComma(v);
						addPicture(v);
						renameFuelType(v);
						updateOrderOfClassX(v);
						capitalizeVehicleTypeName(v);

						return v;
					});
				});
		};

		const fetchVehiclesOrDeals = (query) => {
			let data;
			if (type === "vehicle") {
				if (state.currentQuery === JSON.stringify(query)) {
					return Promise.resolve();
				} else {
					commit("updateIsLoading", true);

					data = fetchVehicles(query).then((vehicles) => {
						commit("updateCurrentQuery", query);
						commit("updateList", vehicles);
					});
				}
			} else if (type === "deals") {
				if (state.currentDealQuery === JSON.stringify(query)) {
					return Promise.resolve();
				} else {
					commit("updateIsLoading", true);

					data = fetchDeals(query).then((deals) => {
						commit("updateCurrentDealQuery", query);
						commit("updateDeals", deals);
					});
				}
			}

			// prettier-ignore
			return data.then(() => {
				commit("itinerary/updateItinerary", itinerary, { root: true });
			})
				.finally(() => {
					commit("updateIsLoading", false);
				});
		};

		const queryFromItinerary = (itinerary) => {
			// query model {
			// "pickupLocation":29,
			// "dropOffLocation":29,
			// "pickupDateTime":"2 Feb 2020 09:00",
			// "dropOffDateTime":"16 Feb 2020 10:00",
			// "agentId":0,
			// "promotion":"",
			// "isMobile":false,
			// }
			return Promise.resolve({
				pickupLocation: itinerary.pickup.branch.deliveryPointId,
				dropOffLocation: itinerary.dropOff.branch.deliveryPointId,
				pickupDateTime: this.$date.format("D MMM YYYY", itinerary.pickup.date) + " " + itinerary.pickup.time.text24,
				dropOffDateTime: this.$date.format("D MMM YYYY", itinerary.dropOff.date) + " " + itinerary.dropOff.time.text24,
				agentId: itinerary.agentId, // reset on every search
				promotion: itinerary.promoCode,
				clientReferenceNumber: itinerary.clientReferenceNumber,
				isMobile: this.$device.isMobile,
			});
		};

		if (state.isLoading) {
			return Promise.resolve();
		} else if (isEmpty(itinerary)) {
			const query = !!itinerary && itinerary.agentId ? itinerary : {};
			return fetchVehiclesOrDeals(query);
		} else {
			return dispatch("itinerary/validate", itinerary, { root: true }).then(queryFromItinerary).then(fetchVehiclesOrDeals);
		}
	},
	fetch({ state, getters, commit, dispatch, rootState }, itinerary) {
		return dispatch("fetchAll", { itinerary });
	},
	fetchDeals({ state, getters, commit, dispatch, rootState }, itinerary) {
		return dispatch("fetchAll", { itinerary, type: "deals" });
	},
};
