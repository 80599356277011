export const state = () => ({
	$config: null,
	showMainMenu: false,
	showSubMenu: false,
	activeSubMenu: '',
	showItinerary: false,
	showFormEmailUs: false,
	isMyAccountModalVisible: false,
	hasPickupLocation: false,
	configuration: null,
	hasItineraryErrors: false,
	itineraryErrors: [],
	userFirstActionHasHappened: false,
	footerStyle: 'normal',
	heading: null,
	carbonOffsetPricePerDay: 0.95,
	processingFees: [],
	disableHeaderCommand: false,
	brand: null
})

export const getters = {
	/**
	 * Normalize state.heading to be display in the home and location pages
	 */
	headingPageHeader: (state) => {
		if (!state.heading) return state.heading
		if (!state.heading?.enabled) return state.heading

		const { title, headline, headlineUrl, headerImage } = state.heading

		return {
			title,
			headline,
			headlineLink	: headlineUrl.replace('https://www.gorentals.co.nz', ''),
			picture			: {
				alt 		: headerImage.altText,
				mobile		: { src: headerImage.mobileImage.data.attributes.url,		width: 375, height: 495 },
				tablet		: { src: headerImage.tabletImage.data.attributes.url,		width: 720, height: 720 },
				tabletWide	: { src: headerImage.tabletWideImage.data.attributes.url,	width: 976, height: 696 },
				desktop		: { src: headerImage.desktopImage.data.attributes.url,		width: 1384, height: 618 },
				desktopWide	: { src: headerImage.desktopWideImage.data.attributes.url,	width: 1864, height: 618 },
			}
		}
	},
}

export const mutations = {
	updateFooterStyle (state, str) {
		state.footerStyle = str
	},
	toggleMainMenu (state, bool) {
		state.showMainMenu = bool
	},
	toggleSubMenu (state, string) {
		state.activeSubMenu = string
		state.showSubMenu = !!string
	},
	toggleFormEmailUs(state) {
		state.showFormEmailUs = !state.showFormEmailUs
	},
	toggleMyAccountModal(state) {
		state.isMyAccountModalVisible = !state.isMyAccountModalVisible
	},
	updateHasPickupLocation(state, val) {
		state.hasPickupLocation = val
	},
	updateItineraryErrors(state, errors) {
		state.hasItineraryErrors = !!errors
		state.itineraryErrors = errors
	},
	toggleItinerary(state, val) {
		state.showItinerary = val
	},
	setUserFirstAction(state) {
		state.userFirstActionHasHappened = true
	},
	setNuxtConfig(state, $config) {
		state.$config = $config
	},
	setHeading(state, heading) {
		state.heading = heading
	},
	setProcessingFees(state, fees) {
		state.processingFees = fees
	},
	setHideHeader(state, payload) {
		state.disableHeaderCommand = payload;
	},
	setBrand(state, brand) {
		state.brand = brand;
	},
}

export const actions = {
	updateFooterStyle ({ state, commit }, str) {
		if (state.footerStyle === str) return
		if (!['normal', 'short'].includes(str)) throw new Error('Invalid footer style')
		commit('updateFooterStyle', str)
	},
	toggleHideHeader({ commit }, payload) {
		commit('setHideHeader', payload);
	},
	toggleMainMenu ({ commit, state }, bool = null) {
		if (state.showMainMenu === bool) return // it's the same, don't need to re-assign
		if (bool === null) {
			commit('toggleMainMenu', !state.showMainMenu)
		} else {
			commit('toggleMainMenu', bool)
		}
	},
	toggleSubMenu ({ commit }, subMenuName = null) {
		commit('toggleSubMenu', subMenuName)
	},
	updateUserFirstAction({ commit }) {
		const commitUserFirstAction = () => {
			if (!state.userFirstActionHasHappened) commit('setUserFirstAction')
		}

		document.addEventListener("click", commitUserFirstAction, { once: true })
		document.addEventListener("scroll", commitUserFirstAction, { once: true })
		document.addEventListener("mousemove", commitUserFirstAction, { once: true })
	},
	async fetchHeading({ state, commit }) {
		if (state.heading) return state.heading
		const endpoint = ""
			+ '/page-header/'
			+ '?' + 'fields=enabled&fields=title&fields=headline&fields=headlineUrl'
			+ '&' + 'populate[headerImage][populate][mobileImage][fields][0]=url'
			+ '&' + 'populate[headerImage][populate][tabletImage][fields][0]=url'
			+ '&' + 'populate[headerImage][populate][tabletWideImage][fields][0]=url'
			+ '&' + 'populate[headerImage][populate][desktopImage][fields][0]=url'
			+ '&' + 'populate[headerImage][populate][desktopWideImage][fields][0]=url'

		const { data: heading } = await this.$strapi.$get(endpoint)
			.catch(err => ({ data: null }))

		commit("setHeading", heading?.attributes)
		return heading
	},
	// https://nuxtjs.org/docs/directory-structure/store/#the-nuxtserverinit-action
	nuxtServerInit ({ commit }, context) {
		commit('setNuxtConfig', context.$config)
	},
	// https://github.com/nuxt/nuxt.js/issues/240#issuecomment-326893386
	nuxtClientInit({ state, dispatch, commit }, context) {
		dispatch('browser/initBreakpoints', null, { root: true })
		dispatch('browser/initIdle', null, { root: true })
		dispatch('browser/initIsVisible', null, { root: true })
		dispatch('updateUserFirstAction')
	},
	async fetchProcessingFees({ state, commit }) {
		const { result: fees } = await this.$axios.$get('/payment/getProcessingFees')
			.catch(err => ({ result: [] }))
		commit("setProcessingFees", fees)
		return fees
	},
	async getProcessingFees({ state, dispatch }) {
		if (state.processingFees.length) return state.processingFees
		return await dispatch('fetchProcessingFees')
	},
	setBrand({ commit }, brand) {
		commit('setBrand', brand);
	}
}
