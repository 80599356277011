export const state = () => ({
	timerId: null,
	activeModal: null
});

export const mutations = {
	toggleTimer(state, { modalFileName, bool }) {
		clearTimeout(state.timerId)
		state.timerId = null

		if (!bool) {
			state.activeModal = null
			return
		}

		if (state.activeModal) return
		if (!modalFileName) return

		state.activeModal = modalFileName
		state.timerId = setTimeout(async () => {
			const modal = await import(`~/components/modals/${modalFileName}.vue`).then(module => module.default || module)
			this.$modalService.show(modal)
		}, this.$config.checkinTimeOutSeconds * 1000)
	},

	async itineraryTimeout(state, callback) {
		const modal = await import(`~/components/modals/itineraryExpiredModal.vue`).then(module => module.default || module)
		this.$modalService
			.create(modal)
			.on("ok", () => {
				callback();
			})
			.show()
	}
};

export const actions = {
	toggleTimer({ commit }, payload) {
		commit("toggleTimer", payload)
	},
	itineraryTimeout({ commit }, callback) {
		commit("itineraryTimeout", callback);
	},
};
