export const state = () => ({
	feedback: {},
});

export const actions = {};

export const mutations = {
	updateFeedback(state, feedback) {
		state.feedback = feedback;
	},
};
