<template>
	<!-- this marginBottom intent to fix scrollbar flickering, when page height is close to content height. -->
	<main class="-mb-16">
		<header>
			<pageHeader1image v-bind="pageHeader">
				<nuxt-link class="font-bold tablet:text-18 rounded-full outline-0 border-2 inline-flex cursor-pointer shadow-lg px-16 py-8 tablet:py-12 text-white hover:text-white active:text-white border-goPink hover:border-goPinkDark active:border-goPinkDarker bg-goPink hover:bg-goPinkDark active:bg-goPinkDarker" to="/help/">
					<span class="mx-8"> Get help </span>
					<span class="mx-8"> <svg-icon name="arrow-forward" class="iconSize-24 opacity-50 mx-8" /> </span>
				</nuxt-link>
			</pageHeader1image>
		</header>
	</main>
</template>

<script>
// ! Warning: Though this file is placed in the layouts folder, it should be treated as a page. */
export default {
	name: "NuxtError",
	components: {
		pageHeader1image: () => import("~/components/pageHeader1image.vue"),
	},
	props: {
		error: {
			type: Object,
			default: null,
		},
	},
	asyncData({ error, req, redirect }) {
		if (req && req.error) {
			return error({ message: req.error.message, statusCode: req.error.status });
		}
		return error({ message: "Page not found", statusCode: 404 });
	},
	data() {
		return {
			pageHeader: {
				picture: {
					alt: "Image of a white Hyundai Santa Fe parked next to the beach with GO Rentals pink licence plates",
					mobile: { src: "/images/Error_Hero_Mobile.jpg" },
					tablet: { src: "/images/Error_Hero_Tablet.jpg" },
					tabletWide: { src: "/images/Error_Hero_Tablet_Wide.jpg" },
					desktop: { src: "/images/Error_Hero_Desktop.jpg" },
					desktopWide: { src: "/images/Error_Hero_Desktop_Wide.jpg" },
				},
				title: "Oops, <br class='tablet:hidden'>page not found",
				headline: "Sorry, we can't seem to find what you’re looking for. <br class='hidden tablet:unset'>Contact us via Help or give us a call for a hand.",
			},
		};
	},
	head() {
		return {
			title: this.message,
		}
	},
	computed: {
		statusCode() {
			return (this.error && this.error.statusCode) || 500;
		},
		message() {
			return this.error.message || `<%= messages.client_error %>`;
		},
	},
	metaInfo() {
		return {
			meta: [{ vmid: "robots", name: "robots", content: "noindex, nofollow" }],
		};
	},
};
</script>
