import Vue from "vue"; // eslint-disable-line
const _modals = [];
export default (context) => ({
	create(modal, props) {
		const newModal = new Modal(context, modal, props);
		_modals.push(newModal);
		return newModal;
	},

	show(modal, props) {
		return this.create(modal, props).show();
	},

	close() {
		_modals.forEach((m) => {
			m.close();
		});
	},
});

class Modal {
	constructor(context, modalDefinition, props) {
		this._modalDefinition = modalDefinition;
		this._props = props;
		this._events = [];
		this._modal = null;
		this._context = context;
	}

	on(event, callback) {
		this._events.push({ event, callback });
		return this;
	}

	show() {
		const plugins = Object.keys(this._context.app).filter((k) => k.indexOf("$") === 0);
		const newContext = {};
		plugins.forEach((prop) => {
			newContext[prop] = this._context.app[prop];
		});

		const options = Object.assign(
			{
				propsData: Object.assign({ visible: true }, this._props || {}),
				store: this._context.store,
				router: this._context.app.router,
				i18n: this._context.app.i18n,
			},
			newContext,
		);

		const modalInstance = new Vue(Object.assign({}, this._modalDefinition, options));

		const container = document.createElement("div");
		document.body.appendChild(container);
		modalInstance.$mount(container);
		for (const event of this._events) {
			modalInstance.$on(event.event, event.callback);
		}
		this._modal = modalInstance;
		return this;
	}

	close(params) {
		this._modal.close(params);
		_modals.pop(this._modal);
	}
}
