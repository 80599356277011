<template>
	<div class="hidden" v-html="gtmScript">
	</div>
</template>

<script>
 export default {
   data() {
		const gtmTestEnvironment = !this.$config.thirdParty.gtmTestEnvironmentParameters ? '' : this.$config.thirdParty.gtmTestEnvironmentParameters;
       	return {
			gtmScript: `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${this.$config.thirdParty.googleTagManagerId}${gtmTestEnvironment}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
       	}
     }
 }
 </script>
