import { branches } from "~/static/data/branches.js";

export const state = () => ({
	list: branches,
});

export const getters = {
	findBy: (state) => (key, value = null) => {
		return key
			? state.list.find((location) => location[key] === value)
			: null;
	},
	bySlug: (state) => {
		return state.list.reduce((acc, location) => {
			acc[location.slug] = location;
			return acc;
		}, {});
	},
};

export const mutations = {};

export const actions = {};
