<template>
	<layoutSection class="
		bg-goPink
		rounded-GO
		tablet:rounded-tr-0
		">
		<footer class="
			desktop:mx-auto
			desktop:max-w-1440
			p-16
			tablet:p-48
			desktop:p-56
			desktopWide:pl-0
		">
			<aside class="
			">
				<nav v-once class="
					text-white
					grid
					grid-cols-2 gap-x-8 gap-y-32
					tablet:grid-cols-3 tablet:gap-y-32 tablet:gap-x-16
					tabletWide:grid-cols-4
					desktop:grid-cols-4 desktop:gap-y-24
					">
					<ul class="space-y-8 tablet:col-span-3 tabletWide:col-span-1">
						<li><nuxt-link to="/" class="link link-white font-bold text-20 tablet:text-24">Home</nuxt-link></li>
						<br class="hidden tablet:unset">
					</ul>
					<ul class="space-y-8">
						<li><nuxt-link to="/go-zero/" class="link link-white font-bold text-20 tablet:text-24">GO Zero</nuxt-link></li>
						<br class="hidden tablet:unset">
					</ul>
					<ul class="space-y-8">
						<li><nuxt-link to="/tesla-rental-nz/" class="link link-white font-bold text-20 tablet:text-24">GO Tesla</nuxt-link></li>
						<br class="hidden tablet:unset">
					</ul>
					<ul class="space-y-8">
						<li><nuxt-link to="/app/" class="link link-white font-bold text-20 tablet:text-24">GO App</nuxt-link></li>
						<br class="hidden tablet:unset">
					</ul>
					<ul class="space-y-8">
						<li><nuxt-link to="/vehicles/" class="link link-white font-bold text-20 tablet:text-24">Vehicles</nuxt-link></li>
						<li><nuxt-link to="/vehicles/small-cars/" class="link link-white">Small cars</nuxt-link></li>
						<li><nuxt-link to="/vehicles/large-cars-suvs/" class="link link-white">Large cars / SUVs</nuxt-link></li>
						<li><nuxt-link to="/vehicles/electric-vehicles/" class="link link-white">Electric vehicles</nuxt-link></li>
						<li><nuxt-link to="/vehicles/hybrid-cars/" class="link link-white">Hybrid cars</nuxt-link></li>
						<li><nuxt-link to="/vehicles/4-wheel-drives/" class="link link-white">4 Wheel drives</nuxt-link></li>
						<li><nuxt-link to="/vehicles/van-or-people-carriers/" class="link link-white">Van or people carriers</nuxt-link></li>
						<li><nuxt-link to="/vehicles/car-rental-accessories/" class="link link-white">Car rental accessories</nuxt-link></li>
					</ul>
					<ul class="space-y-8 tablet:col-span-2 tabletWide:col-span-1">
						<li><nuxt-link to="/deals/" class="link link-white font-bold text-20 tablet:text-24">Deals</nuxt-link></li>
						<li><nuxt-link to="/deals/" class="link link-white">Hot deals</nuxt-link></li>
						<li><nuxt-link to="/rental-car-relocation/" class="link link-white">Relocations deals</nuxt-link></li>
						<li><nuxt-link :to="{ name: 'deals', params: { scrollTarget: 'promoDeal' }}" class="link link-white">Partner deals</nuxt-link></li>
					</ul>
					<ul class="space-y-8">
						<li><nuxt-link to="/rental-car-locations/" class="link link-white font-bold text-20 tablet:text-24">Locations</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/car-rental-auckland/" class="link link-white">Auckland</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/auckland-city/" class="link link-white">Auckland city</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/auckland-airport/" class="link link-white">Auckland airport</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/waiheke/" class="link link-white">Waiheke</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/wellington/" class="link link-white">Wellington airport</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/nelson/" class="link link-white">Nelson airport</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/christchurch/" class="link link-white">Christchurch airport</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/queenstown/" class="link link-white">Queenstown airport</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/dunedin/" class="link link-white">Dunedin airport</nuxt-link></li>
						<li><nuxt-link to="/rental-car-locations/invercargill-airport/" class="link link-white">Invercargill airport</nuxt-link></li>
					</ul>
					<ul class="space-y-8">
						<li><a class="white font-bold text-20 tablet:text-24">Services</a></li>
						<li><nuxt-link to="/services/rental-car-insurance/" class="link link-white">Car rental insurance</nuxt-link></li>
						<li><nuxt-link to="/services/long-term-car-rental/" class="link link-white">Long-term car rental</nuxt-link></li>
						<li><nuxt-link to="/services/one-way-car-rental/" class="link link-white">One-way car rental</nuxt-link></li>
						<li><nuxt-link to="/services/weekend-car-rental/" class="link link-white">Weekend car rental</nuxt-link></li>
					</ul>
					<ul class="space-y-8">
						<li><a href="https://www.gorentals.co.nz/explore/" class="link link-white font-bold text-20 tablet:text-24">Explore NZ</a></li>
						<li><a href="https://www.gorentals.co.nz/explore/trip-planner/" class="link link-white">Trip planner</a></li>
						<li><a href="https://www.gorentals.co.nz/explore/travel-guides/" class="link link-white">Travel guides</a></li>
						<li><nuxt-link to="/go-play/" class="link link-white">GO Play</nuxt-link></li>
						<li><a href="https://www.gorentals.co.nz/explore/blog/" class="link link-white">Travel blog</a></li>
						<li><nuxt-link to="/go-snap-happy/" class="link link-white">Snap happy</nuxt-link></li>
						<li><nuxt-link to="/help/drive-safe/" class="link link-white">Drive safe</nuxt-link></li>
						<li><nuxt-link to="/tiaki-promise/" class="link link-white">Tiaki Promise</nuxt-link></li>
					</ul>
					<ul class="space-y-8">
						<li><nuxt-link to="/about-us/" class="link link-white font-bold text-20 tablet:text-24">About Us</nuxt-link></li>
						<li><nuxt-link to="/about-us/why-choose-go/" class="link link-white">Why go with us?</nuxt-link></li>
						<li><nuxt-link to="/about-us/endorsements/" class="link link-white">Awards & Endorsements</nuxt-link></li>
						<li><nuxt-link to="/about-us/reviews/" class="link link-white">Customer reviews</nuxt-link></li>
						<li><nuxt-link to="/about-us/our-team/" class="link link-white">Our team</nuxt-link></li>
						<li><nuxt-link to="/about-us/work-for-us/" class="link link-white">Work for us</nuxt-link></li>
							<li><a href="https://www.gorentals.co.nz/news/" class="link link-white">News</a></li>
					</ul>
					<ul class="space-y-8">
						<li><nuxt-link to="/help/" class="link link-white font-bold text-20 tablet:text-24">Help</nuxt-link></li>
						<li><nuxt-link to="/help/before-you-book/" class="link link-white">Before you book</nuxt-link></li>
						<li><nuxt-link to="/help/ahead-of-your-trip/" class="link link-white">Ahead of your trip</nuxt-link></li>
						<li><nuxt-link to="/help/on-the-road/" class="link link-white">Help on the road</nuxt-link></li>
						<li><nuxt-link to="/help/after-your-journey/" class="link link-white">After your journey</nuxt-link></li>
						<li><nuxt-link to="/help/account-and-app/" class="link link-white">Account & App</nuxt-link></li>
					</ul>
					<ul class="space-y-8">
						<li><nuxt-link to="/my-account/" class="link link-white font-bold text-20 tablet:text-24">Account</nuxt-link></li>
						<li><nuxt-link to="/manage-booking/" class="link link-white">Manage booking</nuxt-link></li>
						<li><nuxt-link to="/online-check-in/" class="link link-white">Check-in</nuxt-link></li>
					</ul>
				</nav>
			</aside>
		</footer>
	</layoutSection>
</template>

<script>
import layoutSection from '~/components/layoutSection.vue';

export default {
	components: {
		layoutSection,
	},
}
</script>
