import lookupService from "~/services/lookupService.js";
import modalService from "~/services/modalService.js";
import visitService from "~/services/visitService.js";
import date from "~/assets/scripts/utilities/date.js";
import cloudFlare from "~/services/cloudFlare.js";
import timetime from "~/assets/scripts/utilities/time.js";
import error from "~/services/error.js";
import * as helpers from "~/assets/scripts/utilities/helpers.js";
import settings from "~/services/settings.js";
import sessionStorageService from "~/services/sessionStorageService.js";

export default (ctx, inject) => {
	inject("lookupService", lookupService(ctx));
	inject("modalService", modalService(ctx));
	inject("visitService", visitService(ctx));
	inject("date", date);
	inject("cloudFlare", cloudFlare(ctx));
	inject("time", timetime);
	inject("error", error);
	inject("helpers", helpers);
	inject("settings", settings);
	inject("sessionStorageService", sessionStorageService);
};
