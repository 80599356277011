export const state = () => ({
	searchResults: [],
	showResults: false,
	searchTerm: "",
});

export const mutations = {
	updateResults(state, results) {
		state.searchResults = results;
	},
	updateTest(state, results) {},
	updateShowResults(state, showResults) {
		state.showResults = showResults;
	},
	updateSearchTerm(state, searchTerm) {
		state.searchTerm = searchTerm;
	},
};

export const actions = {};
