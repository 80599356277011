<template>
	<header :class="showPromoBar ? 'tablet:h-[8rem] h-[6.25rem]' : 'tablet:h-92 h-48'" data-test="mainHeader" @mouseleave="onMouseLeave">
		<div class="tablet:hidden z-10 fixed w-full">
			<mainHeaderPromoBar isMobile :isShow="showPromoBar" :onHide="hidePropmoBar"/>
			<div class="flex items-center justify-between border-b border-black/10 bg-white">
				<mainHeaderLogoAgent v-if="hideForAgent" class="px-16 py-12 text-goPink" />
				<mainHeaderLogo v-else class="px-16 py-12 text-goPink" />

				<button class="px-16 py-12 border-0 bg-transparent text-goPink" data-test="menuButton" @click="menuButton_onClick">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path d="M1 5h22a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2zm0 6h22a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2zm0 6h22a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z" fill="currentColor"></path>
					</svg>
					<span class="sr-only">Open menu</span>
				</button>
			</div>
		</div>

		<div class="z-20 tablet:z-50 h-full tablet:h-auto fixed w-full top-0 flex flex-col text-white tablet:text-goBlack bg-goPink tablet:bg-white ease-out duration-700 transition-transform will-change-transform tablet:transition-none tablet:translate-x-0 tablet:translate-y-0 "
			:class="[$store.state.showMainMenu ? '' : 'translate-x-full ', activeSubMenu === '' ? '' : 'tablet:border-b tablet:border-black/10']">
			<mainHeaderPromoBar :isShow="showPromoBar" :onHide="hidePropmoBar"/>
			<nav class="tablet:order-last flex-grow flex flex-col tablet:flex-row justify-between desktop:mx-8 ">
				<div class="flex justify-between">
					<mainHeaderLogoAgent v-if="hideForAgent" class="p-24 tablet:py-16 text-white tablet:text-goPink" @click.native="logoLink_onClick" />
					<mainHeaderLogo v-else class="p-24 tablet:py-16 text-white tablet:text-goPink" @click.native="logoLink_onClick" />

					<button class="p-24 tablet:hidden" @click="closeButton_onClick">
						<svg-icon name="x" title="Close" class="iconSize-24 text-white" />
					</button>
				</div>

				<div v-show="!hideForAgent" class=" flex flex-col whitespace-nowrap tablet:flex-row tablet:pr-24 tabletWide:pr-48 desktop:pr-0 " data-test="mainMenu">
					<nuxt-link to="/vehicles/" active-class="opacity-80 tablet:opacity-100 tablet:text-goPinkDark" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @click.native="mainMenulink_onClick('vehicles')"	@mouseenter.native="mainMenulink_onEnter('vehicles')">Vehicles</nuxt-link>
					<nuxt-link to="/go-zero/" active-class="opacity-80 tablet:opacity-100 tablet:text-goPinkDark" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @click.native="mainMenulink_onClick(null)" @mouseenter.native="mainMenulink_onEnter(null)">GO Zero</nuxt-link>
					<nuxt-link to="/tesla-rental-nz/" active-class="opacity-80 tablet:opacity-100 tablet:text-goPinkDark" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @click.native="mainMenulink_onClick(null)" @mouseenter.native="mainMenulink_onEnter(null)">GO Tesla</nuxt-link>
					<nuxt-link to="/rental-car-locations/" active-class="opacity-80 tablet:opacity-100 tablet:text-goPinkDark" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @click.native="mainMenulink_onClick('locations')" @mouseenter.native="mainMenulink_onEnter('locations')">Locations</nuxt-link>
					<nuxt-link to="/deals/" active-class="opacity-80 tablet:opacity-100 tablet:text-goPinkDark" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @click.native="mainMenulink_onClick('deals')" @mouseenter.native="mainMenulink_onEnter('deals')">Deals</nuxt-link>
					<a v-if="isTouchDevice" :href="exploreUrl" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @click.prevent="mainMenulink_onClick('explore')" @mouseenter.prevent="mainMenulink_onEnter('explore')">Explore NZ</a>
					<a v-else :href="exploreUrl" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @mouseenter.prevent="mainMenulink_onEnter('explore')">Explore NZ</a>
					<nuxt-link to="/help/" active-class="opacity-80 tablet:opacity-100 tablet:text-goPinkDark" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @click.native="mainMenulink_onClick('help')" @mouseenter.native="mainMenulink_onEnter('help')">Help</nuxt-link>
					<nuxt-link to="/about-us/" active-class="opacity-80 tablet:opacity-100 tablet:text-goPinkDark" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @click.native="mainMenulink_onClick('aboutUs')" @mouseenter.native="mainMenulink_onEnter('aboutUs')">About Us</nuxt-link>
					<nuxt-link to="/app/" active-class="opacity-80 tablet:opacity-100 tablet:text-goPinkDark" class="text-white font-bold text-24 px-24 py-6 tablet:border-b-2 tablet:border-transparent tablet:text-goBlue tablet:text-14 tablet:px-6 tablet:pt-18 tabletWide:px-12 tabletWide:text-16 desktop:hover:text-goPinkDark desktop:focus:text-goPink desktop:active:text-goPinkDarker" @click.native="mainMenulink_onClick(null)" @mouseenter.native="mainMenulink_onEnter(null)">GO App</nuxt-link>
				</div>

				<!-- used to center the menu -->
				<div class="invisible inline-block p-24 tablet:py-16 tablet:hidden desktop:unset ">
					<svg-icon name="blank" class="h-24 w-[166px] tablet:w-[140px]" />
				</div>
			</nav>

			<div class=" ml-8 tablet:ml-0 tablet:justify-end tablet:order-first flex flex-wrap tablet:flex-nowrap tablet:bg-goGrayBrighter tablet:px-24 tabletWide:px-48 desktop:px-56 ">
				<signInButton class="px-16 py-12 w-full tablet:w-auto tablet:p-6 tabletWide:px-12 tablet:order-5 text-14 font-bold text-white tablet:text-goPinkDark" @click.native="signInButton_onClick"/>
				<nuxt-link to="/online-check-in/" class="px-16 py-12 w-full tablet:w-auto tablet:p-6 tabletWide:px-12 tablet:order-4 inline-flex items-center text-14 font-bold text-white tablet:text-goPinkDark space-x-8" @click.native="checkInLink_onClick"> <svg-icon name="check-in" class=" iconSize-24" /> <span>Check-in</span> </nuxt-link>
				<nuxt-link v-show="!hideForAgent" to="/manage-booking/" class="px-16 py-12 w-full tablet:w-auto tablet:p-6 tabletWide:px-12 tablet:order-3 inline-flex items-center text-14 font-bold text-white tablet:text-goPinkDark space-x-8" @click.native="manageLink_onClick"> <svg-icon name="check-in" class=" iconSize-24" /> <span>Manage booking</span> </nuxt-link>
				<hr class="ml-16 mt-12 w-full tablet:w-auto border-white opacity-50 tablet:hidden" />
				<callUsButton v-show="!hideForAgent" class="p-16 pr-0 tablet:p-6 tabletWide:px-12 tablet:pr-0 tablet:order-1 text-white tablet:text-14 tablet:font-bold tablet:text-goPinkDark" />
				<emailUsButton class="p-16 tablet:p-6 tabletWide:px-12 tablet:order-2 text-white tablet:text-14 tablet:font-bold tablet:text-goPinkDark" />
			</div>
		</div>

		<!-- Sub menues -->
		<template v-if="$store.state.userFirstActionHasHappened">
			<mainHeaderSubMenu :submenu="menuVehicles" title="Vehicles"	:class="getMainHeaderSubMenuClass('vehicles')" @linkClick="subMenuLink_onClick" @closeButtonClick="subMenuCloseButton_onClick" @backButtonClick="subMenuBackButton_onClick" />
			<mainHeaderSubMenu :submenu="menuLocations"	title="Locations" :class="getMainHeaderSubMenuClass('locations')" @linkClick="subMenuLink_onClick" @closeButtonClick="subMenuCloseButton_onClick" @backButtonClick="subMenuBackButton_onClick" />
			<mainHeaderSubMenu :submenu="menuDeals"	title="Deals" :class="getMainHeaderSubMenuClass('deals')" @linkClick="subMenuLink_onClick" @closeButtonClick="subMenuCloseButton_onClick" @backButtonClick="subMenuBackButton_onClick" />
			<mainHeaderSubMenu :submenu="menuExplore" title="Explore" :class="getMainHeaderSubMenuClass('explore')" @linkClick="subMenuLink_onClick" @closeButtonClick="subMenuCloseButton_onClick" @backButtonClick="subMenuBackButton_onClick" />
			<mainHeaderSubMenu :submenu="menuHelp" title="Help" :class="getMainHeaderSubMenuClass('help')" @linkClick="subMenuLink_onClick" @closeButtonClick="subMenuCloseButton_onClick" @backButtonClick="subMenuBackButton_onClick">
				<template #footer>
					<footer class="hidden tablet:unset text-center">
						<div class="pt-16 pb-12">
							<callUsButton class="text-white hover:opacity-90 active:opacity-80 text-14" />
							<emailUsButton class="text-white hover:opacity-90 active:opacity-80 text-14" />
						</div>
					</footer>
				</template>
			</mainHeaderSubMenu>
			<mainHeaderSubMenu :submenu="menuAboutUs" title="About us" :class="getMainHeaderSubMenuClass('aboutUs')" @linkClick="subMenuLink_onClick" @closeButtonClick="subMenuCloseButton_onClick" @backButtonClick="subMenuBackButton_onClick" />
		</template>

		<!-- this is the way to exit the submenu -->
		<button :class="[activeSubMenu === '' ? 'hidden ' : ' device-mouse:hidden']" type="button" class=" z-30 tablet:fixed tablet:top-0 tablet:w-full tablet:h-full tablet:bg-black/25 tablet:p-body " @click.self="overlayButton_onClick" />

		<myAccountModal v-if="$store.state.isMyAccountModalVisible" />
	</header>
</template>

<script>
// Do not lazy load mainHeaderLogo, to prevent web vitals CLS issue.
import mainHeaderLogo from "~/components/mainHeaderLogo.vue"
import mainHeaderLogoAgent from "~/components/mainHeaderLogoAgent.vue"
import mainHeaderPromoBar from "~/components/mainHeaderPromoBar.vue"
import { UserType } from "~/assets/scripts/valueObjects/UserType"

export default {
	components: {
		mainHeaderLogo,
		mainHeaderLogoAgent,
		mainHeaderPromoBar,
		signInButton: () => import(/* webpackChunkName: "signInButton" */ "~/components/signInButton.vue"),
		callUsButton: () => import(/* webpackChunkName: "callUsButton" */ "~/components/callUsButton.vue"),
		emailUsButton: () => import(/* webpackChunkName: "emailUsButton" */ "~/components/emailUsButton.vue"),
		mainHeaderSubMenu: () => import(/* webpackChunkName: "mainHeaderSubMenu" */ "~/components/mainHeaderSubMenu.vue"),
		myAccountModal: () => import(/* webpackChunkName: "myAccountModal" */ "~/components/modals/myAccount.vue"),
	},
	data() {
		return {
			showMobileMainMenu: false,
			isTouchDevice: false,
			isPromoBarShow: false,
			exploreUrl: process.env.goExploreBaseURL,
			menuAboutUs: [],
			menuHelp: [],
			menuExplore: [],
			menuLocations: [],
			menuVehicles: [],
			menuDeals: [],
		};
	},
	async fetch() {
		const [menuAboutUs, menuHelp, menuExplore, menuLocations, menuVehicles, menuDeals] = await Promise.all([
			import("~/static/data/menuAboutUs").then(({ menuAboutUs }) => menuAboutUs),
			import("~/static/data/menuHelp").then(({ menuHelp }) => menuHelp),
			import("~/static/data/menuExplore").then(({ menuExplore }) => menuExplore),
			import("~/static/data/menuLocations").then(({ menuLocations }) => menuLocations),
			import("~/static/data/menuVehicles").then(({ menuVehicles }) => menuVehicles),
			import("~/static/data/menuDeals").then(({ menuDeals }) => menuDeals),
		])
		this.menuAboutUs = menuAboutUs
		this.menuHelp = menuHelp
		this.menuExplore = menuExplore
		this.menuLocations = menuLocations
		this.menuVehicles = menuVehicles
		this.menuDeals = menuDeals
	},
	computed: {
		activeSubMenu() {
			return this.$store.state.activeSubMenu
		},
		hideForAgent() {
			return !!this.$store.state.authentication.user.userType && (this.$store.state.authentication.user.userType === UserType.Agent || this.$store.state.authentication.user.userType === UserType.Corporate)
		},
		showPromoBar() {
			if (this.$route.path.startsWith('/business') || this.hideForAgent) return false
			return this.isPromoBarShow
		}
	},
	watch: {
		activeSubMenu() {
			document.getElementsByTagName("body")[0].classList.toggle("device-touch:overflow-hidden", this.$store.state.showSubMenu);
		},
	},
	beforeMount() {
		this.isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
	},
	methods: {
		getMainHeaderSubMenuClass(subMenuName) {
			return [
				this.activeSubMenu === subMenuName ? '' : 'translate-x-full tablet:translate-x-0 tablet:-translate-y-full',
				this.isPromoBarShow ? 'tablet:pt-[8rem]' : 'tablet:pt-92',
				'fixed z-40 top-0 w-full h-full tablet:h-auto ease-out duration-700 transition-transform will-change-transform tablet:p-body'
			]
		},
		onMouseLeave() {
		 	this.closeSubMenu()
		},
		menuButton_onClick() {
			this.openMainMenu()
		},
		logoLink_onClick() {
			this.closeMainMenu()
		},
		closeButton_onClick() {
			this.closeMainMenu()
		},
		checkInLink_onClick() {
			this.closeMainMenu()
		},
		manageLink_onClick() {
			this.closeMainMenu()
		},
		signInButton_onClick() {
			this.closeMainMenu()
		},
		mainMenulink_onClick(subMenuName) {
			this.openSubMenu(subMenuName)
		},
		mainMenulink_onEnter(subMenuName) {
			if (this.$store.state.browser.breakpoint.isDesktop) {
				this.openSubMenu(subMenuName)
			}
		},
		subMenuLink_onClick() {
			this.closeSubMenu()
			this.closeMainMenu()
		},
		subMenuCloseButton_onClick() {
			this.closeSubMenu()
			this.closeMainMenu()
		},
		subMenuBackButton_onClick() {
			this.closeSubMenu()
		},
		overlayButton_onClick() {
 			this.closeSubMenu()
		},
		closeMainMenu () {
			this.$store.dispatch('toggleMainMenu', false)
		},
		openMainMenu () {
			this.$store.dispatch('toggleMainMenu', true)
		},
		closeSubMenu () {
			this.$store.dispatch('toggleSubMenu', '')
		},
		openSubMenu (subMenuName) {
			if (subMenuName) {
				this.$store.dispatch('toggleSubMenu', subMenuName)
			} else {
				this.closeSubMenu()
				this.closeMainMenu()
			}
		},
		hidePropmoBar() {
			this.isPromoBarShow = false
		}
	},
};
</script>
