<template>
	<img v-if="isSingleImage" src="/images/blank.svg" :data-src="singleImage.src" :width="singleImage.width" :height="singleImage.height" decoding="async" :alt="alt" class="object-cover lazyload" />
	<picture v-else class="flex items-center">
		<template v-if="enablePlaceholder">
			<source v-if="!!desktopWide"	:srcset="`/images/blank.svg`"	:data-srcset="getNewSrc('desktopWide')"		:width="getWidth('desktopWide')"	:height="getHeight('desktopWide')"	media="(min-width: 100em)" />
			<source v-if="!!desktop"		:srcset="`/images/blank.svg`"	:data-srcset="getNewSrc('desktop')"			:width="getWidth('desktop')"		:height="getHeight('desktop')"		media="(min-width: 80em)" />
			<source v-if="!!tabletWide"		:srcset="`/images/blank.svg`"	:data-srcset="getNewSrc('tabletWide')"		:width="getWidth('tabletWide')"		:height="getHeight('tabletWide')"	media="(min-width: 62.5em)" />
			<source v-if="!!tablet"			:srcset="`/images/blank.svg`"	:data-srcset="getNewSrc('tablet')"			:width="getWidth('tablet')"			:height="getHeight('tablet')"		media="(min-width: 43.75em)" />
			<img							src="/images/blank.svg"			:data-src="getNewSrc('mobile')"				:width="getWidth('mobile')"			:height="getHeight('mobile')"		:alt="alt" decoding="async" class="object-cover w-full h-full lazyload" />
		</template>

		<template v-else>
			<source v-if="!!desktopWide"	:srcset="getNewSrc('desktopWide')"	:width="getWidth('desktopWide')"	:height="getHeight('desktopWide')"	media="(min-width: 100em)" />
			<source v-if="!!desktop"		:srcset="getNewSrc('desktop')"		:width="getWidth('desktop')"		:height="getHeight('desktop')"		media="(min-width: 80em)" />
			<source v-if="!!tabletWide"		:srcset="getNewSrc('tabletWide')"	:width="getWidth('tabletWide')"		:height="getHeight('tabletWide')"	media="(min-width: 62.5em)" />
			<source v-if="!!tablet"			:srcset="getNewSrc('tablet')"		:width="getWidth('tablet')"			:height="getHeight('tablet')"		media="(min-width: 43.75em)" />
			<img							:src="getNewSrc('mobile')"			:width="getWidth('mobile')"			:height="getHeight('mobile')"		:alt="alt" class="object-cover w-full h-full"	fetchpriority="high" />
		</template>
	</picture>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		enablePlaceholder: {
			type: Boolean,
			default: true
		},
		alt: {
			type: String,
			default: '',
		},
		mobile: {
			type: [String, Object],
			required: true,
		},
		tablet: {
			type: [String, Object],
			default: null,
		},
		tabletWide: {
			type: [String, Object],
			default: null,
		},
		desktop: {
			type: [String, Object],
			default: null,
		},
		desktopWide: {
			type: [String, Object],
			default: null,
		},
	},
	computed: {
		isSingleImage () {
			return [this.mobile, this.tablet, this.tabletWide, this.desktop, this.desktopWide].filter(Boolean).length === 1
		},
		singleImage () {
			if (!this.isSingleImage) return null

			const singleImage = this.mobile || this.tablet || this.tabletWide || this.desktop || this.desktopWide
			if (!singleImage?.src) throw new Error("No url provided")
			const { src, width, height } = singleImage

			const newSrc = width || height
				? this.$cloudFlare.getImage(src, { width, height })
				: this.$cloudFlare.getImage(src)

			return {
				src: newSrc,
				width: width || null,
				height: height || null,
			}
		},
	},
	methods: {
		getNewSrc(breakpoint) {
			if (!breakpoint) throw new Error("No breakpoint provided")
			if (!this[breakpoint]) throw new Error("No url provided")

			if (this[breakpoint]?.src) {
				const { src, width, height } = this[breakpoint]

				return width || height
					? this.$cloudFlare.getImage(src, { width, height })
					: this.$cloudFlare.getImage(src)
			}

			if (this[breakpoint]) this.$cloudFlare.getImage(this[breakpoint])
		},
		getWidth(breakpoint) {
			return this[breakpoint]?.width
		},
		getHeight(breakpoint) {
			return this[breakpoint]?.height
		},
	}
};
</script>
