import formItineraryPromoCodeModal from "~/components/modals/itinerary/formItineraryPromoCode.vue";

const newItinerary = {
	pickup: {
		branch: null,
		date: null,
		time: null,
	},
	dropOff: {
		branch: null,
		date: null,
		time: null,
	},
	promoCode: null,
	agentId: null,
	clientReferenceNumber: null,
	lastQueryDateTime: new Date()
};

export const state = () => ({
	...newItinerary,
	modalPromoVisible: false,
});

export const getters = {
	isEmpty: (state) => {
		// prettier-ignore
		return false
			|| !state.pickup.branch
			|| !state.pickup.date
			|| !state.pickup.time
			|| !state.dropOff.branch
			|| !state.dropOff.date
			|| !state.dropOff.time
	}
};

export const mutations = {
	togglePromoModal(state) {
		state.modalPromoVisible = !state.modalPromoVisible;
	},
	updateItinerary(state, itinerary = null) {
		itinerary = itinerary || newItinerary;
		itinerary.lastQueryDateTime = new Date();

		itinerary.agentId = itinerary.agentId || state.agentId; // keep agent as long as we can

		if (!!itinerary.pickup && !!itinerary.pickup.branch) {
			itinerary.pickup.branch.openingTime = this.$time.format("HH:MM", itinerary.pickup.branch.openingTime);
			itinerary.pickup.branch.closingTime = this.$time.format("HH:MM", itinerary.pickup.branch.closingTime);
		}

		if (!!itinerary.dropOff && !!itinerary.dropOff.branch) {
			itinerary.dropOff.branch.openingTime = this.$time.format("HH:MM", itinerary.dropOff.branch.openingTime);
			itinerary.dropOff.branch.closingTime = this.$time.format("HH:MM", itinerary.dropOff.branch.closingTime);
		}

		for (const key in itinerary) {
			state[key] = itinerary[key];
		}

		this.commit("itinerary/setCookie");
	},
	setCookie(state) {
		const cookieMaxAge = 60 * 30;
		const temp = state;
		delete temp.modalPromoVisible;

		this.$sessionStorageService.set("itinerary", temp, { path: "/", maxAge: cookieMaxAge });
	},
};

export const actions = {
	togglePromo({ commit, state }, params = null) {
		commit("togglePromoModal");
		if (state.modalPromoVisible) {
			this.$modalService
				.create(formItineraryPromoCodeModal, params)
				.on("close", () => {
					if (state.modalPromoVisible) {
						commit("togglePromoModal");
					}
				})
				.show();
		}
	},
	fromQuery({ rootGetters }, query) {
		// gets a backend itinerary model
		// returns fronend itinerary model

		return new Promise((resolve, reject) => {
			query.pickupLocation.openingTime = this.$time.format("HH:MM", query.pickupLocation.openingTime);
			query.pickupLocation.closingTime = this.$time.format("HH:MM", query.pickupLocation.closingTime);
			query.dropOffLocation.openingTime = this.$time.format("HH:MM", query.dropOffLocation.openingTime);
			query.dropOffLocation.closingTime = this.$time.format("HH:MM", query.dropOffLocation.closingTime);

			const frontEndItinerary = {
				...newItinerary,
				pickup: {
					branch: rootGetters['location/findBy']('code', query.pickupLocation.code),
					date: new Date(query.pickupDate),
					time: this.$time.range.find((timeObj) => timeObj.text24 === query.pickupTime),
				},
				dropOff: {
					branch: rootGetters['location/findBy']('code', query.dropOffLocation.code),
					date: new Date(query.dropOffDate),
					time: this.$time.range.find((timeObj) => timeObj.text24 === query.dropOffTime),
				},
				promoCode: query.promoCode || null,
				agentId: query.agentId || null,
			};

			resolve(frontEndItinerary);
		});
	},
	validate({ state }, itinerary) {
		return new Promise((resolve, reject) => {
			// error: pick-up is not a valid js obj
			if (!this.$date.isDate(itinerary.pickup.date)) {
				reject(new Error("The pick-up date is invalid."));
			}

			// error: drop off is not a valid js obj
			if (!this.$date.isDate(itinerary.dropOff.date)) {
				reject(new Error("The drop off date is invalid."));
			}

			const today = {
				date: parseInt(this.$date.format("YYYYMMDD", this.$date.today)),
				NZ: {
					hour: this.$date.toNZ(this.$date.today).getHours(),
					minute: this.$date.toNZ(this.$date.today).getMinutes(),
				},
			};

			const pickup = {
				branch: itinerary.pickup.branch,
				date: parseInt(this.$date.format("YYYYMMDD", itinerary.pickup.date)),
				time: parseInt(itinerary.pickup.time.text24.split(":").join("")),
				hour: parseInt(this.$time.format("HH", itinerary.pickup.time.text24)),
				minute: parseInt(this.$time.format("MM", itinerary.pickup.time.text24)),
				openingTime: parseInt(itinerary.pickup.branch.openingTime.split(":").join("")),
				closingTime: parseInt(itinerary.pickup.branch.closingTime.split(":").join("")),
				isAllowedAfterHoursPickups: itinerary.pickup.branch.isAllowedAfterHoursPickups,
			};

			const dropOff = {
				branch: itinerary.dropOff.branch,
				date: parseInt(this.$date.format("YYYYMMDD", itinerary.dropOff.date)),
				time: parseInt(itinerary.dropOff.time.text24.split(":").join("")),
				openingTime: parseInt(itinerary.dropOff.branch.openingTime.split(":").join("")),
				closingTime: parseInt(itinerary.dropOff.branch.closingTime.split(":").join("")),
				isAllowedAfterHoursDroppedOff: itinerary.dropOff.branch.isAllowedAfterHoursDroppedOff,
			};

			// error: pick-up is older than today
			if (pickup.date < today.date) {
				reject(new Error("The pick-up date is before today."));
			}

			// error: drop off is older than today
			if (dropOff.date < today.date) {
				reject(new Error("The drop off date is before today"));
			}

			// error: drop off is older than pick-up
			if (dropOff.date < pickup.date) {
				reject(new Error("The drop off date is before the pick-up date"));
			}

			// error: pick-up is today and hour is older than now
			if (pickup.date === today.date && pickup.hour < today.NZ.hour) {
				reject(new Error("You can't choose a pick-up time earlier than now."));
			}

			// error: pick-up is today and hour now and minute is older than now
			if (pickup.date === today.date && pickup.hour === today.NZ.hour && pickup.minute < today.NZ.minute) {
				reject(new Error("You can't choose a pick-up time earlier than now"));
			}

			// error: For the same day make sure, drop off date is !== pick-up date and drop off time is !== pick-up time
			if (pickup.date === dropOff.date && pickup.time === dropOff.time) {
				reject(new Error("Drop-off should be after pick-up."));
			}

			// error: For the same day make sure drop off time is > that pick-up time
			if (pickup.date === dropOff.date && pickup.time > dropOff.time) {
				reject(new Error("Drop-off should be after pick-up"));
			}

			// error: this pick-up location dont allow after hours pick-ups
			if (!pickup.isAllowedAfterHoursPickups) {
				// error: selected pick-up time is before opening
				if (pickup.time < pickup.openingTime) {
					reject(new Error("The pick-up time is outside of branch hours.  <span class='hidden tabletWide:unset' >" + pickup.branch.name + " open time: </span>" + this.$time.format("H:MM APM", pickup.branch.openingTime) + " - " + this.$time.format("H:MM APM", pickup.branch.closingTime)));
				}

				// error: selected pick-up time is after closing
				if (pickup.time > pickup.closingTime) {
					reject(new Error("The pick-up time is outside of branch hours <span class='hidden tabletWide:unset' >" + pickup.branch.name + " open time: </span>" + this.$time.format("H:MM APM", pickup.branch.openingTime) + " - " + this.$time.format("H:MM APM", pickup.branch.closingTime)));
				}
			}

			// error: this drop off location dont allow after hours drop off
			if (!dropOff.isAllowedAfterHoursDroppedOff) {
				// error: selected drop off time is before opening
				if (dropOff.time < dropOff.openingTime) {
					reject(new Error("The drop off time is outside of branch hours. <span class='hidden tabletWide:unset' >" + dropOff.branch.name + " open time: </span>" + this.$time.format("H:MM APM", dropOff.branch.openingTime) + " - " + this.$time.format("H:MM APM", dropOff.branch.closingTime)));
				}

				// error: selected drop off time is after closing
				if (dropOff.time > dropOff.closingTime) {
					reject(new Error("The drop off time is outside of branch hours <span class='hidden tabletWide:unset' >" + dropOff.branch.name + " open time: </span>" + this.$time.format("H:MM APM", dropOff.branch.openingTime) + " - " + this.$time.format("H:MM APM", dropOff.branch.closingTime)));
				}
			}

			resolve(itinerary);
		});
	},
	removePromoCode({ state, commit, dispatch, rootState }, { searchTypeAction }) {
		if (!state.promoCode) return;

		const promo = {
			promoCode: null,
			clientReferenceNumber: null,
		}

		commit("updateItinerary", promo)

		if	(rootState.hasItineraryErrors) {
			commit("updateItineraryErrors", null, { root: true })
		}

		dispatch("vehicle/" + searchTypeAction, {
			...state,
			...promo,
		}, { root: true })
	}
};
