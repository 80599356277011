<script>
export default {
	components: {
		overlay: () => import("~/components/modals/overlay.vue"),
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	beforeMount() {
			this.toggleScrollbar(this.visible)
	},
	methods: {
		ok(data) {
			this.toggleModal(false)
			this.$emit("ok", data);
		},
		close() {
			this.toggleModal(false)
			this.$emit("close");
		},
		toggleModal(bool = null) {
			this.visible = bool === null ? !this.visible : bool
			this.toggleScrollbar(this.visible)
		},
		toggleScrollbar(bool) {
			document.getElementsByTagName('html')[0].classList.toggle("overflow-hidden", bool)
		},
	},
};
</script>
