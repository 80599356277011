const cookieMaxAge = 60 * 60 * 24; // Cookie expires after 24 hours

export const state = () => ({
	campaign: {
		name: ""
	}
});

export const mutations = {
	updateCampaign(state, campaign) {
		state.campaign = campaign;
		this.$cookies.set("campaign", campaign, { path: "/", maxAge: cookieMaxAge });
	}
};
