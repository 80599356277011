export const state = () => ({
	loadingQueue: [],
});

export const mutations = {
	loadingQueuePush(state, data) {
		state.loadingQueue.push(data);
	},
	loadingQueuePop(state) {
		state.loadingQueue.pop();
	},
};

export const actions = {
	loading({ commit, state }, { isLoading }) {
		if (!process.client) return

		if (isLoading) {
			commit("loadingQueuePush", isLoading);
		} else {
			commit("loadingQueuePop");
		}
	},
};
