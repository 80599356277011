<template functional>
	<section :id="props.id" :class="[data.class, data.staticClass]" class="tablet:ml-48 tablet:pr-48 desktop:ml-56 desktop:pr-56 space-y-8 tabletWide:space-y-16">
		<template v-if="'prepend' in $slots">
			<slot name="prepend"></slot>
		</template>

		<div class="desktop:mx-auto desktop:max-w-1328">
			<template v-if="'default' in $slots">
				<slot></slot>
			</template>

			<template v-if="$options.methods.isValidType(props.type)">
				<div class="relative grid grid-cols-12 gap-8 tablet:gap-16">
					<template v-if="'above' in $slots">
						<slot name="above"></slot>
					</template>

					<template v-if="props.type === 'post'">
						<div class="col-span-12 row-start-2 tabletWide:col-span-6 tabletWide:row-start-1">
							<slot name="main"></slot>
						</div>
						<div class="col-span-12 row-start-1 tabletWide:col-span-6">
							<slot name="aside"></slot>
						</div>
					</template>

					<template v-if="props.type === 'post-reversed'">
						<div class="col-span-12 row-start-2 tabletWide:col-span-6 tabletWide:row-start-1 tabletWide:col-start-7">
							<slot name="main"></slot>
						</div>
						<div class="col-span-12 row-start-1 tabletWide:col-span-6">
							<slot name="aside"></slot>
						</div>
					</template>

					<template v-if="props.type === 'post-reversed-flip'">
						<div class="col-span-12 tabletWide:col-span-6 tabletWide:col-start-7">
							<slot name="main"></slot>
						</div>
						<div class="col-span-12 tabletWide:col-span-6 tabletWide:row-start-1">
							<slot name="aside"></slot>
						</div>
					</template>

					<template v-if="props.type === 'post-vertical'">
						<div class="col-span-12 row-start-2">
							<slot name="main"></slot>
						</div>
						<div class="col-span-12 row-start-1">
							<slot name="aside"></slot>
						</div>
					</template>

					<template v-if="props.type === 'post2'">
						<div class="col-span-12 row-start-2 tabletWide:col-span-6 tabletWide:row-start-1">
							<slot name="main"></slot>
						</div>
						<div class="col-span-6 row-start-1 tabletWide:col-span-3">
							<slot name="aside_left"></slot>
						</div>
						<div class="col-span-6 row-start-1 tabletWide:col-span-3">
							<slot name="aside_right"></slot>
						</div>
					</template>

					<template v-if="props.type === 'post2-reversed'">
						<div class="col-span-12 row-start-2 tabletWide:col-span-6 tabletWide:col-start-7 tabletWide:row-start-1">
							<slot name="main"></slot>
						</div>
						<div class="col-span-6 row-start-1 tabletWide:col-span-3 tabletWide:col-start-1">
							<slot name="aside_left"></slot>
						</div>
						<div class="col-span-6 row-start-1 tabletWide:col-span-3 tabletWide:col-start-4">
							<slot name="aside_right"></slot>
						</div>
					</template>

					<template v-if="props.type === 'post3'">
						<div class="col-span-12 row-start-2 tabletWide:col-span-6 tabletWide:row-start-1 tabletWide:row-span-2">
							<slot name="main"></slot>
						</div>
						<div class="col-span-12 row-start-1 tabletWide:col-span-6 tabletWide:col-start-7">
							<slot name="aside_top"></slot>
						</div>
						<div class="col-span-6 row-start-3 tabletWide:col-span-3 tabletWide:row-start-2 tabletWide:col-start-7">
							<slot name="aside_left"></slot>
						</div>
						<div class="col-span-6 row-start-3 tabletWide:col-span-3 tabletWide:row-start-2 tabletWide:col-start-10">
							<slot name="aside_right"></slot>
						</div>
					</template>

					<template v-if="props.type === 'post3-reversed'">
						<div class="col-span-12 row-start-2 tabletWide:col-span-6 tabletWide:row-start-1 tabletWide:row-span-2 tabletWide:col-start-7">
							<slot name="main"></slot>
						</div>
						<div class="col-span-12 row-start-1 tabletWide:col-span-6">
							<slot name="aside_top"></slot>
						</div>
						<div class="col-span-6 row-start-3 tabletWide:col-span-3 tabletWide:row-start-2">
							<slot name="aside_left"></slot>
						</div>
						<div class="col-span-6 row-start-3 tabletWide:col-span-3 tabletWide:row-start-2 tabletWide:col-start-4">
							<slot name="aside_right"></slot>
						</div>
					</template>

					<template v-if="props.type === 'post-grid'">
						<div class="col-span-12 row-start-2 tabletWide:col-span-6 tabletWide:row-start-1 tabletWide:row-span-2">
							<slot name="main"></slot>
						</div>
						<div class="col-span-12 row-start-1 tabletWide:col-span-6 tabletWide:col-start-7">
							<slot name="aside_top"></slot>
						</div>
						<div class="col-span-12 row-start-3 tabletWide:col-span-6 tabletWide:row-start-2 grid grid-cols-2 gap-8 tablet:gap-16">
							<slot name="grid"></slot>
						</div>
					</template>

					<template v-if="props.type === 'post-grid-reversed'">
						<div class="col-span-12 row-start-2 tabletWide:col-span-6 tabletWide:row-start-1 tabletWide:row-span-2 tabletWide:col-start-7">
							<slot name="main"></slot>
						</div>
						<div class="col-span-12 row-start-1 tabletWide:col-span-6">
							<slot name="aside_top"></slot>
						</div>
						<div class="col-span-12 row-start-3 tabletWide:col-span-6 tabletWide:row-start-2 grid grid-cols-2 gap-8 tablet:gap-16">
							<slot name="grid"></slot>
						</div>
					</template>

					<template v-if="props.type === 'funnel'">
						<div class="col-span-12 row-start-2 tabletWide:col-span-7 tabletWide:row-start-1 desktop:col-start-2 desktop:col-span-6">
							<slot name="main"></slot>
						</div>
						<div class="col-span-12 row-start-1 tabletWide:col-start-9 tabletWide:col-span-4">
							<slot name="aside"></slot>
						</div>
					</template>

					<template v-if="props.type === 'single-center'">
						<div class="col-span-12 tabletWide:col-start-3 tabletWide:col-span-8 desktop:col-start-4 desktop:col-span-6">
							<slot name="main"></slot>
						</div>
					</template>

					<template v-if="'below' in $slots">
						<slot name="below"></slot>
					</template>
				</div>
			</template>
		</div>

		<template v-if="'append' in $slots">
			<slot name="append"></slot>
		</template>
	</section>
</template>

<script>
const validTypes = ['post', 'post-reversed', 'post-reversed-flip', 'post-vertical', 'post2', 'post2-reversed', 'post3', 'post3-reversed', 'post-grid', 'post-grid-reversed', 'funnel', 'single-center']
export default {
	props: {
		id: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
			validator: value => validTypes.includes(value)
		},
		containerClass: {
			type: String,
			default: null,
		},
		mainClass: {
			type: String,
			default: null,
		},
	},
	methods: {
		isValidType(type) {
			return validTypes.includes(type)
		}
	},
}
</script>
