module.exports = {
	groups: {
		"small-cars": {
			name: "Small cars",
			slug: "small-cars",
			content: "Our fleet of small cars is perfect for city driving, solo or couples road trips and business trips. With pick-up locations in all the major city airports, Queenstown, Wellington, Auckland and Christchurch, and more, you can quickly pick up the keys, jump straight into a small rental car and zip your way through city culture, coastal towns and lake districts. Our small cars for hire are modern, all automatic transmission and run on petrol.",
			vehicles: [],
			firsVehicleIndex: 0,
			description: "Rent small cars from across New Zealand. Our fleet of compact car hires are perfect for city driving, solo or couples road trips and business trips. ",
		},
		"large-cars-suvs": {
			name: "Large cars / SUVs",
			slug: "large-cars-suvs",
			content: "For the utmost comfort on your holiday with family, friends or all your sports gear, an SUV could be for you. Whether your plan is to complete an epic road trip, go skiing in the mountains or head to the remote areas of New Zealand, GO Rentals large SUV vehicles give you the room to stretch out and enjoy the ride and fit in bulky gear or luggage for all your road trip needs. All of our large cars are modern, have auto transmission and are fuelled by petrol; request a pricing quote on our website today.",
			vehicles: [],
			firsVehicleIndex: 0,
			description: "Rent large cars and SUVs from GO Rentals. For the utmost comfort on your holiday with family, friends or all your sports gear, an SUV could be for you.",
		},
		"electric-vehicles": {
			name: "Electric vehicles",
			slug: "electric-vehicles",
			content: "Ever wondered what it would be like to drive an EV? With GO Rentals you can hire a Tesla Model 3, Tesla Model Y for a smooth and fast driving experience. Tesla’s offer the most luxurious driving experiences with 15-inch media control center, comfortable leather seats and expansive leg room like no other car. With nearly 300 charging stations across New Zealand's North and South Islands, let go of range anxiety and be on your way to a greener, climate-friendly car hire. Compare prices on our website today and feel what it is like to drive a luxury electric vehicle! Before you hit the road, make sure to read through our <a href='/tesla-rental-nz/' class='link link-goBlue font-bold'>Tesla</a>",
			vehicles: [],
			firsVehicleIndex: 0,
			description: "Enjoy the eco-friendly New Zealand holiday of your dreams with GO Rentals’ excellent range of electric vehicles, including cars from Tesla Model 3 and Tesla Model Y.",
		},
		"4-wheel-drives": {
			name: "4 Wheel drives",
			slug: "4-wheel-drives",
			content: "For a limitless and adventurous car rental, book a 4WD car with GO Rentals. Our 4WD car hires make itinerary planning easy, explore remote areas and camping areas and see beautiful areas off-limits to a 2WD. Let GO Rentals' 4WD fleet take you to the uncrowded corners of New Zealand. Did you know we also have roof racks, ski racks and snow chains for hire so you don't need to miss out on any activity.",
			vehicles: [],
			firsVehicleIndex: 0,
			description: "Get your 4WD car rental from GO Rentals today. With vehicles available from 8 locations, get off-road and enjoy a limitless adventure across New Zealand.",
		},
		"van-or-people-carriers": {
			name: "Van or people carriers",
			slug: "van-or-people-carriers",
			content: "Packing the whole family, band, sports team or crew for an adventure? Or even planning to tick off all the sporting activities New Zealand has to offer? Vans or people carrier rentals may be the way to go for you! From 12-seater people carriers to convenient luggage trailers, GO Rentals is here to make your group travel easy and fun.",
			vehicles: [],
			firsVehicleIndex: 0,
			description: "Stress-free minibus hire in New Zealand. Choose from 8, 10 or 12-seater minivans with an optional luggage shuttle trailer. 15% off for AA members.",
		},
		"hybrid-cars": {
			name: "Hybrid cars",
			slug: "hybrid-cars",
			content: `Often hailed as the "best of both worlds", hybrid cars offer the environmental benefits of electric vehicles as well as the convenience of their petrol-powered cousins. If you can't wait to traverse New Zealand in a hybrid vehicle, GO Rentals has a range of options for you to choose from. It's easy to find the perfect car for your journey.`,
			vehicles: [],
			firsVehicleIndex: 0,
			description: "Kick off your New Zealand adventure with competitive rates on a hybrid car rental, allowing you to enjoy a more sustainable trip without range anxiety.",
		},
	}
}
