const _cache = [];
export default (context) => ({
	getLookups(LookupType) {
		return new Promise((resolve, reject) => {
			const results = [];
			const lookupTypeQuerys = [];
			for (const type of LookupType) {
				const cached = _cache.find((c) => c.lookupType === type);
				if (cached) {
					results.push(cached);
				} else {
					lookupTypeQuerys.push(type);
				}
			}

			if (lookupTypeQuerys.length > 0) {
				context.$axios.$get(`/Lookup?types=${lookupTypeQuerys.join(",")}`).then((response) => {
					const lookups = response.result;
					lookups.forEach((lookup) => {
						_cache.push(lookup);
						results.push(lookup);
					});

					resolve(results);
				});
			} else {
				resolve(results);
			}
		});
	},

	getDriveSafeQuestions() {
		return new Promise((resolve, reject) => {
			const cached = _cache.find((c) => c.lookupType === "driveSafeQuestion");
			if (cached) {
				resolve(cached.lookupOptions);
			} else {
				context.$axios.$get("/lookup/getDriverQuestions").then((response) => {
					_cache.push({ lookupType: "driveSafeQuestion", lookupOptions: response.result });
					resolve(response.result);
				});
			}
		});
	},
});
