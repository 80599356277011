import uuidv1 from "uuid/v1";
import { browserDetect } from "~/services/browserDetect";
import ServiceWorker from "~/workers/service.worker.js";

let checkedGaLoadedCount = 1;
export default (context) => ({
	saveConfirmationVisit(rentalId) {
		const cookie = context.app.$cookies.get("visitCookie");
		const gaExpCookie = context.app.$cookies.get("_gaexp");
		let gaLoaded = false;
		const visitType = "confirmation";

		if (typeof ga === "function") {
			gaLoaded = true;
		}

		if (cookie) {
			context.$axios
				.$post(
					"/visit/saveVisitor",
					{
						customerId: cookie,
						landingPage: context.app.router.currentRoute.path,
						gATrackingLoaded: gaLoaded,
						browserVersion: `${browserDetect.browserName}-${browserDetect.browserVersion}`,
						deviceType: browserDetect.device,
						os: browserDetect.os.name,
						resolution: `${window.screen.width}x${window.screen.height}/${window.devicePixelRatio}`,
						rentalId,
						gaExpCookie,
						visitType,
					},
					{ params: { async: true } },
				)
				.catch((err) => {
					console.log("confirmation visit error", err);
				});
		}
	},
	saveVisitAsync() {
		let cookie = context.app.$cookies.get("visitCookie");
		const gaExpCookie = context.app.$cookies.get("_gaexp");

		let gaLoaded = false;
		let visitType = "return";

		if (typeof ga === "function") {
			gaLoaded = true;
		} else if (checkedGaLoadedCount < 5) {
			checkedGaLoadedCount++;
			setTimeout(() => {
				this.saveVisitAsync();
			}, 500);
			return;
		}

		if (!cookie) {
			visitType = "first";
			cookie = uuidv1();
			const cookieMaxAge = 60 * 60 * 24 * 30;
			context.app.$cookies.set("visitCookie", cookie, { maxAge: cookieMaxAge, path: "/", sameSite: "Strict" });
		}

		const serviceWorker = new ServiceWorker();
		serviceWorker.onmessage = (messageEvent) => {
			serviceWorker.terminate();
		};
		serviceWorker.postMessage({
			url: context.app.$axios.defaults.baseURL + "visit/saveVisitor",
			apiKey: process.env.apiKey,
			method: "POST",
			body: {
				customerId: cookie,
				landingPage: context.app.router.currentRoute.path,
				gATrackingLoaded: gaLoaded,
				browserVersion: `${browserDetect.browserName}-${browserDetect.browserVersion}`,
				deviceType: browserDetect.device,
				os: browserDetect.os.name,
				resolution: `${window.screen.width}x${window.screen.height}/${window.devicePixelRatio}`,
				gaExpCookie,
				visitType,
			},
		});
	},
	saveVisit() {
		let cookie = context.app.$cookies.get("visitCookie");
		const gaExpCookie = context.app.$cookies.get("_gaexp");

		let gaLoaded = false;
		let visitType = "return";

		if (typeof ga === "function") {
			gaLoaded = true;
		} else if (checkedGaLoadedCount < 5) {
			checkedGaLoadedCount++;
			setTimeout(() => {
				this.saveVisit();
			}, 500);
			return;
		}

		if (!cookie) {
			visitType = "first";
			cookie = uuidv1();
			const cookieMaxAge = 60 * 60 * 24 * 30;
			context.app.$cookies.set("visitCookie", cookie, { maxAge: cookieMaxAge, path: "/", sameSite: "Strict" });
		}

		context.app.$axios
			.$post(
				"/visit/saveVisitor",
				{
					customerId: cookie,
					landingPage: context.app.router.currentRoute.path,
					gATrackingLoaded: gaLoaded,
					browserVersion: `${browserDetect.browserName}-${browserDetect.browserVersion}`,
					deviceType: browserDetect.device,
					os: browserDetect.os.name,
					resolution: `${window.screen.width}x${window.screen.height}/${window.devicePixelRatio}`,
					gaExpCookie,
					visitType,
				},
				{ params: { async: true } },
			)
			.catch((err) => {
				console.log("visit record error", err);
			});
	},
});
