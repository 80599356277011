import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _180443fa = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _49145a84 = () => interopDefault(import('../pages/aplyid-cancel.vue' /* webpackChunkName: "pages/aplyid-cancel" */))
const _b00d6022 = () => interopDefault(import('../pages/aplyid-success.vue' /* webpackChunkName: "pages/aplyid-success" */))
const _34fd5eef = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _77552dcb = () => interopDefault(import('../pages/book-from-quote.vue' /* webpackChunkName: "pages/book-from-quote" */))
const _39883e94 = () => interopDefault(import('../pages/book-now.vue' /* webpackChunkName: "pages/book-now" */))
const _3914be30 = () => interopDefault(import('../pages/browser-update.vue' /* webpackChunkName: "pages/browser-update" */))
const _2b6cefab = () => interopDefault(import('../pages/cancel-booking/index.vue' /* webpackChunkName: "pages/cancel-booking/index" */))
const _64865972 = () => interopDefault(import('../pages/canstar-blue.vue' /* webpackChunkName: "pages/canstar-blue" */))
const _277e9e57 = () => interopDefault(import('../pages/confirmation.vue' /* webpackChunkName: "pages/confirmation" */))
const _44c3e909 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _7b6c30f0 = () => interopDefault(import('../pages/credit-card-authorisation/index.vue' /* webpackChunkName: "pages/credit-card-authorisation/index" */))
const _77109098 = () => interopDefault(import('../pages/deals/index.vue' /* webpackChunkName: "pages/deals/index" */))
const _698e24ac = () => interopDefault(import('../pages/electric-vehicle-charging-stations.vue' /* webpackChunkName: "pages/electric-vehicle-charging-stations" */))
const _74d2cf6a = () => interopDefault(import('../pages/extend-booking/index.vue' /* webpackChunkName: "pages/extend-booking/index" */))
const _326daccf = () => interopDefault(import('../pages/feedback-complete.vue' /* webpackChunkName: "pages/feedback-complete" */))
const _22f823d3 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _1d9617ec = () => interopDefault(import('../pages/go-play/index.vue' /* webpackChunkName: "pages/go-play/index" */))
const _0221a9f0 = () => interopDefault(import('../pages/go-snap-happy.vue' /* webpackChunkName: "pages/go-snap-happy" */))
const _9af70f8a = () => interopDefault(import('../pages/go-zero.vue' /* webpackChunkName: "pages/go-zero" */))
const _5ff454b4 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _19e599d6 = () => interopDefault(import('../pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _71fdf79e = () => interopDefault(import('../pages/my-account/index.vue' /* webpackChunkName: "pages/my-account/index" */))
const _482e9995 = () => interopDefault(import('../pages/online-check-in/index.vue' /* webpackChunkName: "pages/online-check-in/index" */))
const _6eb688d7 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _e83b54b8 = () => interopDefault(import('../pages/paymentReturn.vue' /* webpackChunkName: "pages/paymentReturn" */))
const _32620eec = () => interopDefault(import('../pages/privacy-cookie-policy-ehi-nz.vue' /* webpackChunkName: "pages/privacy-cookie-policy-ehi-nz" */))
const _0ce937f8 = () => interopDefault(import('../pages/privacy-cookie-policy.vue' /* webpackChunkName: "pages/privacy-cookie-policy" */))
const _7fde973e = () => interopDefault(import('../pages/rental-car-locations/index.vue' /* webpackChunkName: "pages/rental-car-locations/index" */))
const _468895d0 = () => interopDefault(import('../pages/rental-car-relocation.vue' /* webpackChunkName: "pages/rental-car-relocation" */))
const _247ba20a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _11c3787e = () => interopDefault(import('../pages/return-webview.vue' /* webpackChunkName: "pages/return-webview" */))
const _530570cc = () => interopDefault(import('../pages/search-vehicles.vue' /* webpackChunkName: "pages/search-vehicles" */))
const _11f3a76e = () => interopDefault(import('../pages/shuttle-request.vue' /* webpackChunkName: "pages/shuttle-request" */))
const _0ab09303 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _1f46e979 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _4a8c35d5 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _2cfd2ee8 = () => interopDefault(import('../pages/tesla-model-3-and-model-y-guide.vue' /* webpackChunkName: "pages/tesla-model-3-and-model-y-guide" */))
const _a07c0920 = () => interopDefault(import('../pages/tesla-rental-nz/index.vue' /* webpackChunkName: "pages/tesla-rental-nz/index" */))
const _20470306 = () => interopDefault(import('../pages/tiaki-promise.vue' /* webpackChunkName: "pages/tiaki-promise" */))
const _db15bcc2 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _47d24ea8 = () => interopDefault(import('../pages/vehicles/index.vue' /* webpackChunkName: "pages/vehicles/index" */))
const _66b8327b = () => interopDefault(import('../pages/verify-account.vue' /* webpackChunkName: "pages/verify-account" */))
const _cc8c9ba6 = () => interopDefault(import('../pages/your-feedback.vue' /* webpackChunkName: "pages/your-feedback" */))
const _26f03738 = () => interopDefault(import('../pages/about-us/endorsements.vue' /* webpackChunkName: "pages/about-us/endorsements" */))
const _5ad9b99d = () => interopDefault(import('../pages/about-us/our-team.vue' /* webpackChunkName: "pages/about-us/our-team" */))
const _2cf95a8c = () => interopDefault(import('../pages/about-us/reviews.vue' /* webpackChunkName: "pages/about-us/reviews" */))
const _4df1594a = () => interopDefault(import('../pages/about-us/why-choose-go.vue' /* webpackChunkName: "pages/about-us/why-choose-go" */))
const _54c700cf = () => interopDefault(import('../pages/about-us/work-for-us.vue' /* webpackChunkName: "pages/about-us/work-for-us" */))
const _b02d34a2 = () => interopDefault(import('../pages/business/book-now.vue' /* webpackChunkName: "pages/business/book-now" */))
const _2e1d4646 = () => interopDefault(import('../pages/business/cancel-booking/index.vue' /* webpackChunkName: "pages/business/cancel-booking/index" */))
const _b71e801c = () => interopDefault(import('../pages/business/confirmation.vue' /* webpackChunkName: "pages/business/confirmation" */))
const _380cd858 = () => interopDefault(import('../pages/business/forgot-password.vue' /* webpackChunkName: "pages/business/forgot-password" */))
const _5482e04c = () => interopDefault(import('../pages/business/my-account/index.vue' /* webpackChunkName: "pages/business/my-account/index" */))
const _50509c88 = () => interopDefault(import('../pages/business/sign-in.vue' /* webpackChunkName: "pages/business/sign-in" */))
const _13aa8f44 = () => interopDefault(import('../pages/cancel-booking/agent.vue' /* webpackChunkName: "pages/cancel-booking/agent" */))
const _036a643d = () => interopDefault(import('../pages/cancel-booking/before-you-go.vue' /* webpackChunkName: "pages/cancel-booking/before-you-go" */))
const _4a9613ec = () => interopDefault(import('../pages/cancel-booking/confirmation.vue' /* webpackChunkName: "pages/cancel-booking/confirmation" */))
const _cd30ae8a = () => interopDefault(import('../pages/cancel-booking/contact-support.vue' /* webpackChunkName: "pages/cancel-booking/contact-support" */))
const _38117584 = () => interopDefault(import('../pages/cancel-booking/corporate.vue' /* webpackChunkName: "pages/cancel-booking/corporate" */))
const _13818b99 = () => interopDefault(import('../pages/cancel-booking/no-charge.vue' /* webpackChunkName: "pages/cancel-booking/no-charge" */))
const _a2e4743c = () => interopDefault(import('../pages/cancel-booking/payment/index.vue' /* webpackChunkName: "pages/cancel-booking/payment/index" */))
const _6d1d5d73 = () => interopDefault(import('../pages/credit-card-authorisation/complete.vue' /* webpackChunkName: "pages/credit-card-authorisation/complete" */))
const _7691dc7e = () => interopDefault(import('../pages/credit-card-authorisation/form.vue' /* webpackChunkName: "pages/credit-card-authorisation/form" */))
const _ee19662e = () => interopDefault(import('../pages/deals/promotion.vue' /* webpackChunkName: "pages/deals/promotion" */))
const _2f84e5fa = () => interopDefault(import('../pages/extend-booking/agreement.vue' /* webpackChunkName: "pages/extend-booking/agreement" */))
const _8150b316 = () => interopDefault(import('../pages/extend-booking/at-branch.vue' /* webpackChunkName: "pages/extend-booking/at-branch" */))
const _527b774c = () => interopDefault(import('../pages/extend-booking/confirmation.vue' /* webpackChunkName: "pages/extend-booking/confirmation" */))
const _29e057dc = () => interopDefault(import('../pages/extend-booking/key-terms.vue' /* webpackChunkName: "pages/extend-booking/key-terms" */))
const _8d34378e = () => interopDefault(import('../pages/extend-booking/no-charge.vue' /* webpackChunkName: "pages/extend-booking/no-charge" */))
const _b95662fc = () => interopDefault(import('../pages/extend-booking/payment/index.vue' /* webpackChunkName: "pages/extend-booking/payment/index" */))
const _621898df = () => interopDefault(import('../pages/extend-booking/summary.vue' /* webpackChunkName: "pages/extend-booking/summary" */))
const _50fddacc = () => interopDefault(import('../pages/help/drive-safe.vue' /* webpackChunkName: "pages/help/drive-safe" */))
const _12216dbf = () => interopDefault(import('../pages/manage-booking/my-bookings.vue' /* webpackChunkName: "pages/manage-booking/my-bookings" */))
const _08f85d98 = () => interopDefault(import('../pages/my-account/address-phone.vue' /* webpackChunkName: "pages/my-account/address-phone" */))
const _1870ff46 = () => interopDefault(import('../pages/my-account/driver-licence.vue' /* webpackChunkName: "pages/my-account/driver-licence" */))
const _615c67da = () => interopDefault(import('../pages/my-account/my-bookings.vue' /* webpackChunkName: "pages/my-account/my-bookings" */))
const _b221476a = () => interopDefault(import('../pages/my-account/profile/index.vue' /* webpackChunkName: "pages/my-account/profile/index" */))
const _70ffd94d = () => interopDefault(import('../pages/online-check-in/agreement.vue' /* webpackChunkName: "pages/online-check-in/agreement" */))
const _1d274ec2 = () => interopDefault(import('../pages/online-check-in/confirmation.vue' /* webpackChunkName: "pages/online-check-in/confirmation" */))
const _779206e0 = () => interopDefault(import('../pages/online-check-in/credit-card-pre-authorisation.vue' /* webpackChunkName: "pages/online-check-in/credit-card-pre-authorisation" */))
const _e3fecdd4 = () => interopDefault(import('../pages/online-check-in/credit-card-security.vue' /* webpackChunkName: "pages/online-check-in/credit-card-security" */))
const _186e1356 = () => interopDefault(import('../pages/online-check-in/creditcard.vue' /* webpackChunkName: "pages/online-check-in/creditcard" */))
const _91af3616 = () => interopDefault(import('../pages/online-check-in/driver.vue' /* webpackChunkName: "pages/online-check-in/driver" */))
const _7d49b4a0 = () => interopDefault(import('../pages/online-check-in/extras.vue' /* webpackChunkName: "pages/online-check-in/extras" */))
const _de720a7e = () => interopDefault(import('../pages/online-check-in/hirer.vue' /* webpackChunkName: "pages/online-check-in/hirer" */))
const _65da461d = () => interopDefault(import('../pages/online-check-in/insurance.vue' /* webpackChunkName: "pages/online-check-in/insurance" */))
const _73d2205c = () => interopDefault(import('../pages/online-check-in/key-terms.vue' /* webpackChunkName: "pages/online-check-in/key-terms" */))
const _50062382 = () => interopDefault(import('../pages/online-check-in/loading.vue' /* webpackChunkName: "pages/online-check-in/loading" */))
const _2e23e5cc = () => interopDefault(import('../pages/online-check-in/payment/index.vue' /* webpackChunkName: "pages/online-check-in/payment/index" */))
const _35b2e50a = () => interopDefault(import('../pages/online-check-in/save.vue' /* webpackChunkName: "pages/online-check-in/save" */))
const _dbf44188 = () => interopDefault(import('../pages/online-check-in/shuttle.vue' /* webpackChunkName: "pages/online-check-in/shuttle" */))
const _c29520ae = () => interopDefault(import('../pages/online-check-in/summary.vue' /* webpackChunkName: "pages/online-check-in/summary" */))
const _2906b07a = () => interopDefault(import('../pages/payment/confirmation/index.vue' /* webpackChunkName: "pages/payment/confirmation/index" */))
const _78854e46 = () => interopDefault(import('../pages/rental-car-locations/auckland-airport.vue' /* webpackChunkName: "pages/rental-car-locations/auckland-airport" */))
const _21bb8882 = () => interopDefault(import('../pages/rental-car-locations/auckland-city-branch.vue' /* webpackChunkName: "pages/rental-car-locations/auckland-city-branch" */))
const _49861500 = () => interopDefault(import('../pages/rental-car-locations/auckland-city.vue' /* webpackChunkName: "pages/rental-car-locations/auckland-city" */))
const _02ee4ae6 = () => interopDefault(import('../pages/rental-car-locations/car-rental-auckland.vue' /* webpackChunkName: "pages/rental-car-locations/car-rental-auckland" */))
const _5ff0ee57 = () => interopDefault(import('../pages/rental-car-locations/invercargill-airport.vue' /* webpackChunkName: "pages/rental-car-locations/invercargill-airport" */))
const _1af28908 = () => interopDefault(import('../pages/rental-car-locations/waiheke/index.vue' /* webpackChunkName: "pages/rental-car-locations/waiheke/index" */))
const _6c054000 = () => interopDefault(import('../pages/rental-car-locations/wellington.vue' /* webpackChunkName: "pages/rental-car-locations/wellington" */))
const _2ee6e868 = () => interopDefault(import('../pages/services/long-term-car-rental.vue' /* webpackChunkName: "pages/services/long-term-car-rental" */))
const _3c63e041 = () => interopDefault(import('../pages/services/one-way-car-rental.vue' /* webpackChunkName: "pages/services/one-way-car-rental" */))
const _6400f197 = () => interopDefault(import('../pages/services/rental-car-insurance.vue' /* webpackChunkName: "pages/services/rental-car-insurance" */))
const _d84825fc = () => interopDefault(import('../pages/services/weekend-car-rental.vue' /* webpackChunkName: "pages/services/weekend-car-rental" */))
const _62d42534 = () => interopDefault(import('../pages/tesla-rental-nz/faq.vue' /* webpackChunkName: "pages/tesla-rental-nz/faq" */))
const _2361d06a = () => interopDefault(import('../pages/vehicles/4-wheel-drives.vue' /* webpackChunkName: "pages/vehicles/4-wheel-drives" */))
const _61b92b36 = () => interopDefault(import('../pages/vehicles/car-rental-accessories.vue' /* webpackChunkName: "pages/vehicles/car-rental-accessories" */))
const _0d2a17d1 = () => interopDefault(import('../pages/vehicles/electric-vehicles.vue' /* webpackChunkName: "pages/vehicles/electric-vehicles" */))
const _5fecc0ac = () => interopDefault(import('../pages/vehicles/hybrid-cars.vue' /* webpackChunkName: "pages/vehicles/hybrid-cars" */))
const _07c3b156 = () => interopDefault(import('../pages/vehicles/large-cars-suvs.vue' /* webpackChunkName: "pages/vehicles/large-cars-suvs" */))
const _1a760f3b = () => interopDefault(import('../pages/vehicles/small-cars.vue' /* webpackChunkName: "pages/vehicles/small-cars" */))
const _4e1435e2 = () => interopDefault(import('../pages/vehicles/van-or-people-carriers.vue' /* webpackChunkName: "pages/vehicles/van-or-people-carriers" */))
const _6c4f28f1 = () => interopDefault(import('../pages/business/cancel-booking/confirmation.vue' /* webpackChunkName: "pages/business/cancel-booking/confirmation" */))
const _0ac68cd4 = () => interopDefault(import('../pages/business/cancel-booking/contact-support.vue' /* webpackChunkName: "pages/business/cancel-booking/contact-support" */))
const _624cd15c = () => interopDefault(import('../pages/business/my-account/invoices.vue' /* webpackChunkName: "pages/business/my-account/invoices" */))
const _7bcb6667 = () => interopDefault(import('../pages/business/my-account/manage-booking.vue' /* webpackChunkName: "pages/business/my-account/manage-booking" */))
const _1bf27e26 = () => interopDefault(import('../pages/business/my-account/new-booking.vue' /* webpackChunkName: "pages/business/my-account/new-booking" */))
const _d3e62534 = () => interopDefault(import('../pages/business/my-account/profile/index.vue' /* webpackChunkName: "pages/business/my-account/profile/index" */))
const _2085da39 = () => interopDefault(import('../pages/business/my-account/reports.vue' /* webpackChunkName: "pages/business/my-account/reports" */))
const _a039f210 = () => interopDefault(import('../pages/cancel-booking/payment/account2account.vue' /* webpackChunkName: "pages/cancel-booking/payment/account2account" */))
const _74a76f74 = () => interopDefault(import('../pages/cancel-booking/payment/credit-card.vue' /* webpackChunkName: "pages/cancel-booking/payment/credit-card" */))
const _d9743cd0 = () => interopDefault(import('../pages/cancel-booking/payment/refund.vue' /* webpackChunkName: "pages/cancel-booking/payment/refund" */))
const _8c24ffd8 = () => interopDefault(import('../pages/extend-booking/payment/credit-card.vue' /* webpackChunkName: "pages/extend-booking/payment/credit-card" */))
const _375fecf8 = () => interopDefault(import('../pages/extend-booking/payment/refund.vue' /* webpackChunkName: "pages/extend-booking/payment/refund" */))
const _5da3695e = () => interopDefault(import('../pages/my-account/profile/change-password.vue' /* webpackChunkName: "pages/my-account/profile/change-password" */))
const _12ac6b3c = () => interopDefault(import('../pages/online-check-in/payment/account2account.vue' /* webpackChunkName: "pages/online-check-in/payment/account2account" */))
const _b1471044 = () => interopDefault(import('../pages/online-check-in/payment/credit-card.vue' /* webpackChunkName: "pages/online-check-in/payment/credit-card" */))
const _0673beee = () => interopDefault(import('../pages/online-check-in/payment/refund.vue' /* webpackChunkName: "pages/online-check-in/payment/refund" */))
const _0d2478ce = () => interopDefault(import('../pages/payment/confirmation/account2account.vue' /* webpackChunkName: "pages/payment/confirmation/account2account" */))
const _6abe2053 = () => interopDefault(import('../pages/rental-car-locations/waiheke/popular-destinations.vue' /* webpackChunkName: "pages/rental-car-locations/waiheke/popular-destinations" */))
const _7d6976ac = () => interopDefault(import('../pages/business/my-account/profile/change-password.vue' /* webpackChunkName: "pages/business/my-account/profile/change-password" */))
const _63619744 = () => interopDefault(import('../pages/help/_slug.vue' /* webpackChunkName: "pages/help/_slug" */))
const _678822dc = () => interopDefault(import('../pages/rental-car-locations/_slug/index.vue' /* webpackChunkName: "pages/rental-car-locations/_slug/index" */))
const _069537a0 = () => interopDefault(import('../pages/rental-car-locations/_slug/faq.vue' /* webpackChunkName: "pages/rental-car-locations/_slug/faq" */))
const _8821b802 = () => interopDefault(import('../pages/rental-car-locations/_slug/popular-destinations.vue' /* webpackChunkName: "pages/rental-car-locations/_slug/popular-destinations" */))
const _bee310c0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us/",
    component: _180443fa,
    pathToRegexpOptions: {"strict":true},
    name: "about-us"
  }, {
    path: "/aplyid-cancel/",
    component: _49145a84,
    pathToRegexpOptions: {"strict":true},
    name: "aplyid-cancel"
  }, {
    path: "/aplyid-success/",
    component: _b00d6022,
    pathToRegexpOptions: {"strict":true},
    name: "aplyid-success"
  }, {
    path: "/app/",
    component: _34fd5eef,
    pathToRegexpOptions: {"strict":true},
    name: "app"
  }, {
    path: "/book-from-quote/",
    component: _77552dcb,
    pathToRegexpOptions: {"strict":true},
    name: "book-from-quote"
  }, {
    path: "/book-now/",
    component: _39883e94,
    pathToRegexpOptions: {"strict":true},
    name: "book-now"
  }, {
    path: "/browser-update/",
    component: _3914be30,
    pathToRegexpOptions: {"strict":true},
    name: "browser-update"
  }, {
    path: "/cancel-booking/",
    component: _2b6cefab,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking"
  }, {
    path: "/canstar-blue/",
    component: _64865972,
    pathToRegexpOptions: {"strict":true},
    name: "canstar-blue"
  }, {
    path: "/confirmation/",
    component: _277e9e57,
    pathToRegexpOptions: {"strict":true},
    name: "confirmation"
  }, {
    path: "/cookie-policy/",
    component: _44c3e909,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy"
  }, {
    path: "/credit-card-authorisation/",
    component: _7b6c30f0,
    pathToRegexpOptions: {"strict":true},
    name: "credit-card-authorisation"
  }, {
    path: "/deals/",
    component: _77109098,
    pathToRegexpOptions: {"strict":true},
    name: "deals"
  }, {
    path: "/electric-vehicle-charging-stations/",
    component: _698e24ac,
    pathToRegexpOptions: {"strict":true},
    name: "electric-vehicle-charging-stations"
  }, {
    path: "/extend-booking/",
    component: _74d2cf6a,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking"
  }, {
    path: "/feedback-complete/",
    component: _326daccf,
    pathToRegexpOptions: {"strict":true},
    name: "feedback-complete"
  }, {
    path: "/forgot-password/",
    component: _22f823d3,
    pathToRegexpOptions: {"strict":true},
    name: "forgot-password"
  }, {
    path: "/go-play/",
    component: _1d9617ec,
    pathToRegexpOptions: {"strict":true},
    name: "go-play"
  }, {
    path: "/go-snap-happy/",
    component: _0221a9f0,
    pathToRegexpOptions: {"strict":true},
    name: "go-snap-happy"
  }, {
    path: "/go-zero/",
    component: _9af70f8a,
    pathToRegexpOptions: {"strict":true},
    name: "go-zero"
  }, {
    path: "/help/",
    component: _5ff454b4,
    pathToRegexpOptions: {"strict":true},
    name: "help"
  }, {
    path: "/manage-booking/",
    component: _19e599d6,
    pathToRegexpOptions: {"strict":true},
    name: "manage-booking"
  }, {
    path: "/my-account/",
    component: _71fdf79e,
    pathToRegexpOptions: {"strict":true},
    name: "my-account"
  }, {
    path: "/online-check-in/",
    component: _482e9995,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in"
  }, {
    path: "/payment/",
    component: _6eb688d7,
    pathToRegexpOptions: {"strict":true},
    name: "payment"
  }, {
    path: "/paymentReturn/",
    component: _e83b54b8,
    pathToRegexpOptions: {"strict":true},
    name: "paymentReturn"
  }, {
    path: "/privacy-cookie-policy-ehi-nz/",
    component: _32620eec,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-cookie-policy-ehi-nz"
  }, {
    path: "/privacy-cookie-policy/",
    component: _0ce937f8,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-cookie-policy"
  }, {
    path: "/rental-car-locations/",
    component: _7fde973e,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations"
  }, {
    path: "/rental-car-relocation/",
    component: _468895d0,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-relocation"
  }, {
    path: "/reset-password/",
    component: _247ba20a,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password"
  }, {
    path: "/return-webview/",
    component: _11c3787e,
    pathToRegexpOptions: {"strict":true},
    name: "return-webview"
  }, {
    path: "/search-vehicles/",
    component: _530570cc,
    pathToRegexpOptions: {"strict":true},
    name: "search-vehicles"
  }, {
    path: "/shuttle-request/",
    component: _11f3a76e,
    pathToRegexpOptions: {"strict":true},
    name: "shuttle-request"
  }, {
    path: "/sign-in/",
    component: _0ab09303,
    pathToRegexpOptions: {"strict":true},
    name: "sign-in"
  }, {
    path: "/sign-up/",
    component: _1f46e979,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up"
  }, {
    path: "/terms/",
    component: _4a8c35d5,
    pathToRegexpOptions: {"strict":true},
    name: "terms"
  }, {
    path: "/tesla-model-3-and-model-y-guide/",
    component: _2cfd2ee8,
    pathToRegexpOptions: {"strict":true},
    name: "tesla-model-3-and-model-y-guide"
  }, {
    path: "/tesla-rental-nz/",
    component: _a07c0920,
    pathToRegexpOptions: {"strict":true},
    name: "tesla-rental-nz"
  }, {
    path: "/tiaki-promise/",
    component: _20470306,
    pathToRegexpOptions: {"strict":true},
    name: "tiaki-promise"
  }, {
    path: "/unsubscribe/",
    component: _db15bcc2,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe"
  }, {
    path: "/vehicles/",
    component: _47d24ea8,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles"
  }, {
    path: "/verify-account/",
    component: _66b8327b,
    pathToRegexpOptions: {"strict":true},
    name: "verify-account"
  }, {
    path: "/your-feedback/",
    component: _cc8c9ba6,
    pathToRegexpOptions: {"strict":true},
    name: "your-feedback"
  }, {
    path: "/about-us/endorsements/",
    component: _26f03738,
    pathToRegexpOptions: {"strict":true},
    name: "about-us-endorsements"
  }, {
    path: "/about-us/our-team/",
    component: _5ad9b99d,
    pathToRegexpOptions: {"strict":true},
    name: "about-us-our-team"
  }, {
    path: "/about-us/reviews/",
    component: _2cf95a8c,
    pathToRegexpOptions: {"strict":true},
    name: "about-us-reviews"
  }, {
    path: "/about-us/why-choose-go/",
    component: _4df1594a,
    pathToRegexpOptions: {"strict":true},
    name: "about-us-why-choose-go"
  }, {
    path: "/about-us/work-for-us/",
    component: _54c700cf,
    pathToRegexpOptions: {"strict":true},
    name: "about-us-work-for-us"
  }, {
    path: "/business/book-now/",
    component: _b02d34a2,
    pathToRegexpOptions: {"strict":true},
    name: "business-book-now"
  }, {
    path: "/business/cancel-booking/",
    component: _2e1d4646,
    pathToRegexpOptions: {"strict":true},
    name: "business-cancel-booking"
  }, {
    path: "/business/confirmation/",
    component: _b71e801c,
    pathToRegexpOptions: {"strict":true},
    name: "business-confirmation"
  }, {
    path: "/business/forgot-password/",
    component: _380cd858,
    pathToRegexpOptions: {"strict":true},
    name: "business-forgot-password"
  }, {
    path: "/business/my-account/",
    component: _5482e04c,
    pathToRegexpOptions: {"strict":true},
    name: "business-my-account"
  }, {
    path: "/business/sign-in/",
    component: _50509c88,
    pathToRegexpOptions: {"strict":true},
    name: "business-sign-in"
  }, {
    path: "/cancel-booking/agent/",
    component: _13aa8f44,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-agent"
  }, {
    path: "/cancel-booking/before-you-go/",
    component: _036a643d,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-before-you-go"
  }, {
    path: "/cancel-booking/confirmation/",
    component: _4a9613ec,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-confirmation"
  }, {
    path: "/cancel-booking/contact-support/",
    component: _cd30ae8a,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-contact-support"
  }, {
    path: "/cancel-booking/corporate/",
    component: _38117584,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-corporate"
  }, {
    path: "/cancel-booking/no-charge/",
    component: _13818b99,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-no-charge"
  }, {
    path: "/cancel-booking/payment/",
    component: _a2e4743c,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-payment"
  }, {
    path: "/credit-card-authorisation/complete/",
    component: _6d1d5d73,
    pathToRegexpOptions: {"strict":true},
    name: "credit-card-authorisation-complete"
  }, {
    path: "/credit-card-authorisation/form/",
    component: _7691dc7e,
    pathToRegexpOptions: {"strict":true},
    name: "credit-card-authorisation-form"
  }, {
    path: "/deals/promotion/",
    component: _ee19662e,
    pathToRegexpOptions: {"strict":true},
    name: "deals-promotion"
  }, {
    path: "/extend-booking/agreement/",
    component: _2f84e5fa,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking-agreement"
  }, {
    path: "/extend-booking/at-branch/",
    component: _8150b316,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking-at-branch"
  }, {
    path: "/extend-booking/confirmation/",
    component: _527b774c,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking-confirmation"
  }, {
    path: "/extend-booking/key-terms/",
    component: _29e057dc,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking-key-terms"
  }, {
    path: "/extend-booking/no-charge/",
    component: _8d34378e,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking-no-charge"
  }, {
    path: "/extend-booking/payment/",
    component: _b95662fc,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking-payment"
  }, {
    path: "/extend-booking/summary/",
    component: _621898df,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking-summary"
  }, {
    path: "/help/drive-safe/",
    component: _50fddacc,
    pathToRegexpOptions: {"strict":true},
    name: "help-drive-safe"
  }, {
    path: "/manage-booking/my-bookings/",
    component: _12216dbf,
    pathToRegexpOptions: {"strict":true},
    name: "manage-booking-my-bookings"
  }, {
    path: "/my-account/address-phone/",
    component: _08f85d98,
    pathToRegexpOptions: {"strict":true},
    name: "my-account-address-phone"
  }, {
    path: "/my-account/driver-licence/",
    component: _1870ff46,
    pathToRegexpOptions: {"strict":true},
    name: "my-account-driver-licence"
  }, {
    path: "/my-account/my-bookings/",
    component: _615c67da,
    pathToRegexpOptions: {"strict":true},
    name: "my-account-my-bookings"
  }, {
    path: "/my-account/profile/",
    component: _b221476a,
    pathToRegexpOptions: {"strict":true},
    name: "my-account-profile"
  }, {
    path: "/online-check-in/agreement/",
    component: _70ffd94d,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-agreement"
  }, {
    path: "/online-check-in/confirmation/",
    component: _1d274ec2,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-confirmation"
  }, {
    path: "/online-check-in/credit-card-pre-authorisation/",
    component: _779206e0,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-credit-card-pre-authorisation"
  }, {
    path: "/online-check-in/credit-card-security/",
    component: _e3fecdd4,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-credit-card-security"
  }, {
    path: "/online-check-in/creditcard/",
    component: _186e1356,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-creditcard"
  }, {
    path: "/online-check-in/driver/",
    component: _91af3616,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-driver"
  }, {
    path: "/online-check-in/extras/",
    component: _7d49b4a0,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-extras"
  }, {
    path: "/online-check-in/hirer/",
    component: _de720a7e,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-hirer"
  }, {
    path: "/online-check-in/insurance/",
    component: _65da461d,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-insurance"
  }, {
    path: "/online-check-in/key-terms/",
    component: _73d2205c,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-key-terms"
  }, {
    path: "/online-check-in/loading/",
    component: _50062382,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-loading"
  }, {
    path: "/online-check-in/payment/",
    component: _2e23e5cc,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-payment"
  }, {
    path: "/online-check-in/save/",
    component: _35b2e50a,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-save"
  }, {
    path: "/online-check-in/shuttle/",
    component: _dbf44188,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-shuttle"
  }, {
    path: "/online-check-in/summary/",
    component: _c29520ae,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-summary"
  }, {
    path: "/payment/confirmation/",
    component: _2906b07a,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation"
  }, {
    path: "/rental-car-locations/auckland-airport/",
    component: _78854e46,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-auckland-airport"
  }, {
    path: "/rental-car-locations/auckland-city-branch/",
    component: _21bb8882,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-auckland-city-branch"
  }, {
    path: "/rental-car-locations/auckland-city/",
    component: _49861500,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-auckland-city"
  }, {
    path: "/rental-car-locations/car-rental-auckland/",
    component: _02ee4ae6,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-car-rental-auckland"
  }, {
    path: "/rental-car-locations/invercargill-airport/",
    component: _5ff0ee57,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-invercargill-airport"
  }, {
    path: "/rental-car-locations/waiheke/",
    component: _1af28908,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-waiheke"
  }, {
    path: "/rental-car-locations/wellington/",
    component: _6c054000,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-wellington"
  }, {
    path: "/services/long-term-car-rental/",
    component: _2ee6e868,
    pathToRegexpOptions: {"strict":true},
    name: "services-long-term-car-rental"
  }, {
    path: "/services/one-way-car-rental/",
    component: _3c63e041,
    pathToRegexpOptions: {"strict":true},
    name: "services-one-way-car-rental"
  }, {
    path: "/services/rental-car-insurance/",
    component: _6400f197,
    pathToRegexpOptions: {"strict":true},
    name: "services-rental-car-insurance"
  }, {
    path: "/services/weekend-car-rental/",
    component: _d84825fc,
    pathToRegexpOptions: {"strict":true},
    name: "services-weekend-car-rental"
  }, {
    path: "/tesla-rental-nz/faq/",
    component: _62d42534,
    pathToRegexpOptions: {"strict":true},
    name: "tesla-rental-nz-faq"
  }, {
    path: "/vehicles/4-wheel-drives/",
    component: _2361d06a,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-4-wheel-drives"
  }, {
    path: "/vehicles/car-rental-accessories/",
    component: _61b92b36,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-car-rental-accessories"
  }, {
    path: "/vehicles/electric-vehicles/",
    component: _0d2a17d1,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-electric-vehicles"
  }, {
    path: "/vehicles/hybrid-cars/",
    component: _5fecc0ac,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-hybrid-cars"
  }, {
    path: "/vehicles/large-cars-suvs/",
    component: _07c3b156,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-large-cars-suvs"
  }, {
    path: "/vehicles/small-cars/",
    component: _1a760f3b,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-small-cars"
  }, {
    path: "/vehicles/van-or-people-carriers/",
    component: _4e1435e2,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-van-or-people-carriers"
  }, {
    path: "/business/cancel-booking/confirmation/",
    component: _6c4f28f1,
    pathToRegexpOptions: {"strict":true},
    name: "business-cancel-booking-confirmation"
  }, {
    path: "/business/cancel-booking/contact-support/",
    component: _0ac68cd4,
    pathToRegexpOptions: {"strict":true},
    name: "business-cancel-booking-contact-support"
  }, {
    path: "/business/my-account/invoices/",
    component: _624cd15c,
    pathToRegexpOptions: {"strict":true},
    name: "business-my-account-invoices"
  }, {
    path: "/business/my-account/manage-booking/",
    component: _7bcb6667,
    pathToRegexpOptions: {"strict":true},
    name: "business-my-account-manage-booking"
  }, {
    path: "/business/my-account/new-booking/",
    component: _1bf27e26,
    pathToRegexpOptions: {"strict":true},
    name: "business-my-account-new-booking"
  }, {
    path: "/business/my-account/profile/",
    component: _d3e62534,
    pathToRegexpOptions: {"strict":true},
    name: "business-my-account-profile"
  }, {
    path: "/business/my-account/reports/",
    component: _2085da39,
    pathToRegexpOptions: {"strict":true},
    name: "business-my-account-reports"
  }, {
    path: "/cancel-booking/payment/account2account/",
    component: _a039f210,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-payment-account2account"
  }, {
    path: "/cancel-booking/payment/credit-card/",
    component: _74a76f74,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-payment-credit-card"
  }, {
    path: "/cancel-booking/payment/refund/",
    component: _d9743cd0,
    pathToRegexpOptions: {"strict":true},
    name: "cancel-booking-payment-refund"
  }, {
    path: "/extend-booking/payment/credit-card/",
    component: _8c24ffd8,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking-payment-credit-card"
  }, {
    path: "/extend-booking/payment/refund/",
    component: _375fecf8,
    pathToRegexpOptions: {"strict":true},
    name: "extend-booking-payment-refund"
  }, {
    path: "/my-account/profile/change-password/",
    component: _5da3695e,
    pathToRegexpOptions: {"strict":true},
    name: "my-account-profile-change-password"
  }, {
    path: "/online-check-in/payment/account2account/",
    component: _12ac6b3c,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-payment-account2account"
  }, {
    path: "/online-check-in/payment/credit-card/",
    component: _b1471044,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-payment-credit-card"
  }, {
    path: "/online-check-in/payment/refund/",
    component: _0673beee,
    pathToRegexpOptions: {"strict":true},
    name: "online-check-in-payment-refund"
  }, {
    path: "/payment/confirmation/account2account/",
    component: _0d2478ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-account2account"
  }, {
    path: "/rental-car-locations/waiheke/popular-destinations/",
    component: _6abe2053,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-waiheke-popular-destinations"
  }, {
    path: "/business/my-account/profile/change-password/",
    component: _7d6976ac,
    pathToRegexpOptions: {"strict":true},
    name: "business-my-account-profile-change-password"
  }, {
    path: "/help/:slug/",
    component: _63619744,
    pathToRegexpOptions: {"strict":true},
    name: "help-slug"
  }, {
    path: "/rental-car-locations/:slug?/",
    component: _678822dc,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-slug"
  }, {
    path: "/rental-car-locations/:slug?/faq/",
    component: _069537a0,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-slug-faq"
  }, {
    path: "/rental-car-locations/:slug?/popular-destinations/",
    component: _8821b802,
    pathToRegexpOptions: {"strict":true},
    name: "rental-car-locations-slug-popular-destinations"
  }, {
    path: "/",
    component: _bee310c0,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
