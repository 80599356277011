import { ClassCode } from "~/assets/scripts/valueObjects/ClassCode"
import { getVehicleClassCodeImageAlt } from "~/assets/scripts/utilities/vehicleClassCodeImageAlt.js"

export function getVehiclesImages({ vehicle, cdnUrl }) {
	const imageCount = 5
	const images = []
	if (!vehicle) return images

	for (let i = 0; i < imageCount; i++) {
		const filename = `GROUP-${vehicle.classCode}-${i}`
		const src = cdnUrl + 'cars/' + filename + '.jpg'
		const alt = getVehicleClassCodeImageAlt[filename]
		images.push({ alt, mobile: { src } })
	}

	return images
}