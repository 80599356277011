/**
 * Response from GO api
 * @param {obj} response
 */
export const getResponseError = (response) => {
	if (!process.server && response?.statusCode === 401) {
		window.location.assign("/sign-in/")
	}

	const responseError = !response
		? 'Oops! This doesn’t seem to be working how we want it to. Please try again in a few minutes.'
		: response.errors
		? response.errors[0].errorMessage
		: response.statusCode !== 200
		? 'This doesn’t seem to be working how we want it to. Please try again in a few minutes.'
		: false;

	if (responseError) {
		throw new Error(responseError);
	} else {
		return response;
	}
};

export default {
	getResponseError,
};
