import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate, {
	inject: false,
});

const phoneRule = {
	getMessage(field, args) {
		return `Invalid phone number`;
	},
	validate(value, args) {
		const phoneExp = /^([()+0-9\s]{7,}|\s)+$/;
		return phoneExp.test(value);
	},
};

VeeValidate.Validator.extend("phone", phoneRule);

const dateValidRule = {
	getMessage: (field) => `Date is not valid.`,
	validate: (value) =>
		new Promise((resolve) => {
			if (value) {
				const str = value.replace(/\//g, "-");
				const matched = str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
				if (matched) {
					const [day, month, year] = str.split("-");
					const date = new Date(month + "/" + day + "/" + year);
					resolve({
						valid: !isNaN(date) && date.getDate() === parseInt(day),
					});
				} else {
					resolve({
						valid: false,
					});
				}
			}

			resolve({
				valid: true,
			});
		}),
};

VeeValidate.Validator.extend("dateValid", dateValidRule);

const dobDateRule = {
	getMessage(field, args) {
		return `Date should be in the past`;
	},
	validate(value, args) {
		if (value) {
			const str = value.replace(/\//g, "-");
			if (str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/)) {
				const [day, month, year] = str.split("-");
				const convertDate = new Date(year, month - 1, day);
				return convertDate < new Date() && convertDate > new Date(1790, 1, 1);
			}
		}
		return true;
	},
};

VeeValidate.Validator.extend("dob", dobDateRule);

const expiryDateRule = {
	getMessage(field, args) {
		return `The Licence has expired.`;
	},
	validate(value, args) {
		if (value) {
			const str = value.replace(/\//g, "-");
			if (str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/)) {
				const [day, month, year] = str.split("-");
				const convertDate = new Date(year, month - 1, day);
				return convertDate > new Date() && convertDate < new Date(9999, 1, 1);
			}
		}

		return true;
	},
};

VeeValidate.Validator.extend("expiry", expiryDateRule);

const numericRule = {
	getMessage(field, args) {
		return `This field may only contain numeric characters.`;
	},
	validate(value, args) {
		const numeriExp = /^[0-9]+$/;
		return numeriExp.test(value);
	},
};

VeeValidate.Validator.extend("numeric", numericRule);

const greaterThanOrEqualDate = {
	getMessage(field, { compareToDate }) {
		return `Date must be before your drop off date: ${compareToDate}`;
	},
	validate(value, { compareToDate }) {
		// both date expected to be in DD-MM-YYYY format
		const dateArr = value.split("-");
		if (dateArr.length !== 3) return false
		const [day, month, year] = dateArr
		const [dropoffday, dropoffmonth, dropoffyear] = compareToDate.split("-");
		const date = new Date(year, month - 1, day);
		const dropoff = new Date(dropoffyear, dropoffmonth - 1, dropoffday);
		return date >= dropoff;
	}
};

VeeValidate.Validator.extend("greaterThanOrEqualDate", greaterThanOrEqualDate, {
	paramNames: ["compareToDate"]
});

const dictionary = {
	en: {
		messages: {
			confirmed: () => 'Passwords do not match. Please make sure your passwords match',
		}
	}
}

VeeValidate.Validator.localize(dictionary)
