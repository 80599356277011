export const favicons = {
	/**
	 * docs: https://css-tricks.com/favicon-quiz/
	 * generator: https://realfavicongenerator.net/
	 */

	meta: [
		{ name: "apple-mobile-web-app-title", content:"GO Rentals" },
		{ name: "application-name", content:"GO Rentals" },
		{ name: "msapplication-TileColor", content:"#e21266" },
		{ name: "msapplication-TileImage", content:"/mstile-144x144.png" },
		{ name: "msapplication-config", content:"/browserconfig.xml" },
		{ name: "theme-color", content:"#ffffff" },
	],
	link: [
		{ rel: "apple-touch-icon", sizes:"180x180", href:"/apple-touch-icon.png" },
		{ rel: "icon", type:"image/png", sizes:"32x32", href:"/favicon-32x32.png" },
		{ rel: "icon", type:"image/png", sizes:"16x16", href:"/favicon-16x16.png" },
		{ rel: "manifest", href:"/site.webmanifest" },
		{ rel: "mask-icon", href:"/safari-pinned-tab.svg", color:"#e21266" },
		{ rel: "shortcut icon", href:"/favicon.ico" },
	],
};
